import moment from 'moment-timezone';
import * as providerActions from 'provider/actions/bookingActions';
import { getUserInfo, sendBookerSSN } from 'booker/actions/userActions';
import { innerTrendsMessageRegister } from 'utils/innerTrends';
import {
  closeUrgentNotification,
  bookingFirstCheckin,
  bookerContactConfirmation,
  bookerNotifiedProviderEngaged,
  bookingSecondCheckin,
  finalizeBookingByProvider,
  finalizeBookingForBooker,
  bookerDisputeReconciliation,
  removeUrgentActionOnBE
} from 'common/actions/notificationActions';

const notificationTypes = ({ notification, dispatch, history, role }) => {
  const { event_name: name, event_data: data } = notification;
  let time;
  let waitAfterClose;
  let contentHolder = ''; // Used for changing text in notification based cancelation time
  // Load booking to have the most update data when get a urgent action
  if (data && data.booking_id) {
    if (role === 'provider') {
      const bookingType = data.booking_type || 'Job';
      dispatch(
        providerActions.getBooking({
          bookingType,
          id: data.booking_id,
          showLoading: false
        })
      );
    }
  }

  const onPageRedirect = () => {
    if (history.location.pathname === `/provider/bookings/${data.booking_id}`) {
      history.push('/provider/bookings');
    }
  };

  const removeModal = () => {
    dispatch(closeUrgentNotification(notification.id, waitAfterClose));
    dispatch(removeUrgentActionOnBE(notification.id));
  };

  if (role === 'booker') {
    innerTrendsMessageRegister({
      messageType: 'warning',
      messageName: name,
      displayedMessage: name
    });
  }

  switch (name) {
    case 'booker_contacted_inquiry':
      waitAfterClose = 60; // in seconds
      return {
        waitAfterClose,
        title: 'Booker Contact',
        content: `Just checking in to see if you have been in contact with ${
          data.booking_contact_name
        } regarding your ${moment(data.booking_start_date).format(
          'dddd, MMMM D'
        )} booking. If not, please check in to confirm final details. Don’t forget to discuss COVID precaution preferences!`,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Yes',
            color: 'primary',
            action: () => {
              dispatch(
                bookerContactConfirmation({
                  bookingCommunicationsRecordId:
                    data.booking_communications_record_id,
                  confirmationAnswer: 'provider'
                })
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          },
          {
            id: 2,
            title: 'No',
            color: 'link',
            action: () => {
              dispatch(
                bookerContactConfirmation({
                  bookingCommunicationsRecordId:
                    data.booking_communications_record_id,
                  confirmationAnswer: 'chat'
                })
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              history.push(
                `/provider/bookings/${data.booking_id}?booking_type=${data.booking_type}&chat=true`
              );
            }
          }
        ]
      };
    case 'booking_first_checkin':
      waitAfterClose = 60; // in seconds
      time = data.is_childcare
        ? data.booking_time
        : moment(data.booking_start_time_stamp)
            .tz('America/Denver')
            .format('hh:mma z');
      return {
        waitAfterClose,
        title: `You have a booking on ${moment(data.booking_date).format(
          'dddd, MMMM D'
        )}!`,
        content: `Just a friendly reminder that you have a booking coming up with ${
          data.booking_contact_name
        } at ${time} on ${moment(data.booking_date).format(
          'dddd, MMMM D'
        )}. Please confirm you're going to go.`,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Confirm',
            color: 'primary',
            action: () => {
              dispatch(
                bookingFirstCheckin({
                  communicationsRecordId: data.booking_communications_record_id,
                  checkinAction: 'confirm'
                })
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          },
          {
            id: 2,
            title: 'I need to Cancel',
            color: 'link',
            action: () => {
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              history.push(
                `/provider/bookings/${data.booking_id}?booking_type=Job&cancel_modal=open`
              );
            }
          }
        ]
      };
    case 'booking_accepted_by_provider':
      waitAfterClose = 5; // in seconds
      time = data.is_childcare
        ? data.booking_time
        : moment(data.booking_start_time_stamp)
            .tz('America/Denver')
            .format('hh:mma z');
      return {
        waitAfterClose,
        title: 'A provider has accepted your booking!',
        content: `Congratulations! ${
          data.booking_provider_name
        } has accepted your booking on ${moment(data.booking_date).format(
          'dddd, MMMM D'
        )} at ${time}. They'll be in touch soon to confirm details.`,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'View',
            color: 'primary',
            action: () => {
              dispatch(
                bookerNotifiedProviderEngaged({
                  communicationsRecordId: data.booking_communications_record_id
                })
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              history.push(`/bookings/${data.booking_id}`);
            }
          },
          {
            id: 2,
            title: 'Dismiss',
            color: 'secondary',
            action: () => {
              dispatch(
                bookerNotifiedProviderEngaged({
                  communicationsRecordId: data.booking_communications_record_id
                })
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          }
        ]
      };
    case 'second_checkin':
      waitAfterClose = 5; // in seconds
      time = data.is_childcare
        ? data.booking_time
        : moment(data.booking_start_time_stamp)
            .tz('America/Denver')
            .format('hh:mma z');
      return {
        waitAfterClose,
        title: `Your booking with ${data.booking_contact_name} is coming up soon!`,
        content: `Your booking with ${data.booking_contact_name} at ${time} is coming up soon. Please confirm so we can let ${data.booking_contact_name} know you are on your way!`,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Confirm',
            color: 'primary',
            action: () => {
              dispatch(
                bookingSecondCheckin({
                  communicationsRecordId: data.booking_communications_record_id,
                  checkinAction: 'confirm'
                })
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          },
          {
            id: 2,
            title: 'CANCEL',
            color: 'link',
            action: () => {
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(removeUrgentActionOnBE(notification.id));
              history.push(
                `/provider/bookings/${notification.event_data.booking_id}?booking_type=${notification.event_data.booking_type}&cancel_modal=open`
              );
            }
          }
        ]
      };
    case 'payout_account_needed':
      waitAfterClose = 300; // in seconds
      return {
        waitAfterClose,
        title: 'Payout Account Info Needed',
        content: `Please add your bank account so we can send you payment for your upcoming booking.`,
        hasCloseButton: true,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Update',
            color: 'primary',
            action: () => {
              history.push(`/provider/my-details?payout=true`);
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          }
        ]
      };
    case 'booking_concluded':
      waitAfterClose = 2; // in seconds
      return {
        waitAfterClose,
        title: `Need to make changes to your booking with ${data.booking_contact_name}?`,
        content: `Now that your booking has ended, do you need to make any changes to the start or end times, or number of kids?`,
        hasCloseButton: false,
        showCloseButton: true,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Yes, I need to make changes',
            color: 'primary',
            action: () => {
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(removeUrgentActionOnBE(notification.id));
              history.push(
                `/provider/bookings/${data.booking_id}?booking_type=${data.booking_type}`
              );
            }
          },
          {
            id: 2,
            title: 'No, keep the original times',
            color: 'link',
            action: () => {
              dispatch(
                finalizeBookingByProvider({
                  bookingId: data.booking_id,
                  bookingType: data.booking_type
                })
              );
              dispatch(providerActions.wasReconciled(data.booking_id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(removeUrgentActionOnBE(notification.id));
            }
          }
        ]
      };
    case 'review_booking':
      waitAfterClose = 120; // in seconds
      return {
        waitAfterClose,
        title: `Following up on your booking with ${data.booking_provider_name}`,
        content: `Your booking with ${
          data.booking_provider_name
        } has been finalized for $${(
          Number(data.booking_total_amount) / 100
        ).toFixed(2)}. Let us know how it went by leaving a review for ${
          data.booking_provider_name
        }!`,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Review',
            color: 'primary',
            action: () => {
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              history.push(
                `/bookings/${data.booking_id}?review_invite=${true}`
              );
            }
          },
          {
            id: 2,
            title: 'Dismiss',
            color: 'secondary',
            action: () => {
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          }
        ]
      };
    case 'provider_changed_endtime':
      waitAfterClose = 5; // in seconds
      contentHolder = `${
        data.booking_provider_name
      } requested an adjustment to the booking details for your booking on ${moment(
        data.booking_start_date
      ).format('dddd, MMMM D')}. `;
      if (data.booking_new_start_date && data.booking_new_start_time) {
        contentHolder += `The start time was changed to ${
          data.booking_new_start_time[0] === '0'
            ? data.booking_new_start_time.slice(1)
            : data.booking_new_start_time
        }. `;
      }
      if (data.booking_new_end_date && data.booking_new_end_time) {
        contentHolder += `The end time was changed to ${
          data.booking_new_end_time[0] === '0'
            ? data.booking_new_end_time.slice(1)
            : data.booking_new_end_time
        }. `;
      }
      if (data.new_number_of_kids) {
        contentHolder += `The number of children was changed to ${data.new_number_of_kids}. `;
      }
      contentHolder += `Please, approve or reject these changes.`;
      return {
        waitAfterClose,
        title: `Please review your new booking details`,
        content: contentHolder,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Approve',
            color: 'primary',
            action: () => {
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(
                finalizeBookingForBooker({
                  bookingId: data.booking_id,
                  bookingType: data.booking_type,
                  providerId: data.provider_id
                })
              );
              history.push(
                `/bookings/${data.booking_id}?booking_type=${data.booking_type}`
              );
            }
          },
          {
            id: 2,
            title: 'Reject',
            color: 'secondary',
            action: () => {
              dispatch(
                bookerDisputeReconciliation(data.booking_id, data.booking_type)
              );
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(providerActions.getBookingsSummary()); // This is returning a 500. Should this be bookerActions? Or removed?
              history.push(
                `/bookings/${data.booking_id}?booking_type=${data.booking_type}&reconcile=true`
              );
            }
          }
        ]
      };
    case 'booking_cancelled_by_booker':
      waitAfterClose = 5; // in seconds
      if (data.cancel_penalty) {
        contentHolder = `${data.booker_name} has canceled the booking on ${
          data.booking_date
        }.  You will be receiving cancellation compensation of $${(
          data.cancel_fee_in_cents / 100
        ).toFixed(
          2
        )} due to the short notice. This change has been reflected on your Bookings screen.`;
      } else {
        contentHolder = `${data.booker_name} has canceled their booking with you on ${data.booking_date}. This change has been reflected on your Bookings screen.`;
      }

      return {
        waitAfterClose,
        title: `Your booking with ${data.booker_name} has been canceled.`,
        content: contentHolder,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Close',
            color: 'primary',
            action: () => {
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(providerActions.getBookingsSummary());
              onPageRedirect();
            }
          }
        ]
      };
    case 'booking_cancelled_by_provider':
      waitAfterClose = 5; // in seconds
      return {
        waitAfterClose,
        title: `Your booking was canceled.`,
        content: `Uh oh. It looks like ${
          data.provider_name.split(' ')[0]
        } is unable to make it for your booking on ${
          data.booking_date
        }. We’re working on finding you a replacement and will let you know when we’ve found one.`,
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Close',
            color: 'primary',
            action: () => {
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              dispatch(removeUrgentActionOnBE(notification.id));
            }
          }
        ]
      };
    case 'please_finish_onboarding':
      waitAfterClose = 5; // in seconds
      dispatch(getUserInfo());
      return {
        waitAfterClose,
        title: `Onboarding Info Missing`,
        content:
          'We’ve added some new onboarding features that you’ll need to complete before you’re eligible to take a booking.',
        hasCloseButton: false,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Finish onboarding',
            color: 'primary',
            action: () => {
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
              history.push(`/provider/onboarding/getting-started`);
            }
          },
          {
            id: 2,
            title: 'Not now',
            color: 'secondary',
            action: () => {
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          }
        ]
      };
    case 'ask_booker_ssn':
      waitAfterClose = 60; // in seconds
      return {
        waitAfterClose,
        title: `Identity Verification`,
        content:
          'Our basic background search is having trouble identifying you. To make sure we have the right person, please enter the last 4 digits of your social security number.',
        warning:
          'This information will only be used to help identify you, and will not be stored.',
        form: [
          {
            label: 'Social Security Number',
            name: 'ssn',
            type: 'number'
          }
        ],
        hasCloseButton: true,
        hideModal: () => removeModal(),
        buttons: [
          {
            id: 1,
            title: 'Submit',
            color: 'primary',
            action: ({ ssn }) => {
              dispatch(sendBookerSSN(ssn));
              dispatch(removeUrgentActionOnBE(notification.id));
              dispatch(
                closeUrgentNotification(notification.id, waitAfterClose)
              );
            }
          }
        ]
      };
    default:
      return {
        title: 'Important',
        buttons: [],
        content: name,
        actions: [],
        hasCloseButton: true,
        showCloseButton: false
      };
  }
};

export default notificationTypes;
