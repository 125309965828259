/* eslint-disable import/prefer-default-export */
import actionTypes from 'constants/actionTypes';
import { api } from 'api/connect';
import moment from 'dayjs';

export function getOpportunitiesSummary(showLoading = true) {
  return (dispatch) => {
    if (showLoading) {
      dispatch({
        type: actionTypes.PROVIDER_OPPORTUNITIES_LOADING,
        loading: true
      });
    }

    return api()
      .get('/provider/opportunities?variant=summary&type=full')
      .then((response) => {
        const { data } = response || {};
        const { opportunities } = data || {};
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITIES_SUMMARY_LOADED,
          opportunities
        });
        return Promise.resolve();
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let opportunityErrors = {
          message: 'Something went wrong getting OPPORTUNITIES'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            opportunityErrors = { message: data.error };
          } else {
            opportunityErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITIES_ERRORS,
          errors: opportunityErrors
        });
        return Promise.reject();
      });
  };
}

export function getOpportunities(args) {
  const { variant = 'summary', offset = 0, limit = 20 } = args || {};
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_OPPORTUNITIES_LOADING,
      loading: true
    });

    api()
      .get(
        `/provider/opportunities?variant=${variant}&offset=${offset}&limit=${limit}&type=full`
      )
      .then((response) => {
        const { data } = response || {};
        const { opportunities } = data || [];
        if (variant === 'upcoming') {
          dispatch({
            type: actionTypes.PROVIDER_OPPORTUNITIES_LOADED,
            opportunities
          });
        }
        if (variant === 'past') {
          dispatch({
            type: actionTypes.PROVIDER_PAST_OPPORTUNITIES_LOADED,
            opportunities
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let opportunitiesErrors = {
          message: 'Something went wrong getting Opportunities'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            opportunitiesErrors = { message: data.error };
          } else {
            opportunitiesErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITIES_ERRORS,
          errors: opportunitiesErrors
        });
      });
  };
}

export function getOpportunityById(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_OPPORTUNITIES_LOADING,
      loading: true
    });

    api()
      .get(`/provider/opportunities/${id}`)
      .then((response) => {
        const { data } = response || {};
        const { opportunity } = data || [];
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITY_LOADED,
          opportunity
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let opportunitiesErrors = {
          message: 'Something went wrong getting Opportunities'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            opportunitiesErrors = { message: data.error };
          } else {
            opportunitiesErrors = { ...data.error };
          }
        }
        if (response.status === 404) {
          opportunitiesErrors = {
            notFound: true,
            message: 'Opportunity not found'
          };
        }
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITIES_ERRORS,
          errors: opportunitiesErrors
        });
      });
  };
}

export function assignOpportunityToProvider(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_OPPORTUNITIES_LOADING,
      loading: true
    });

    api()
      .post(`provider/opportunities/${id}/accept`)
      .then((response) => {
        const { data = {} } = response;
        const { job, opportunity } = data;
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITY_ASSIGNMENT_LOADED
        });
        if (opportunity && opportunity.id) {
          dispatch({
            type: actionTypes.PROVIDER_OPPORTUNITY_LOADED,
            opportunity
          });
        }
        if (job && job.id) {
          dispatch({
            type: actionTypes.PROVIDER_OPPORTUNITY_REMOVE,
            opportunityId: id
          });
          dispatch({
            type: actionTypes.PROVIDER_UPCOMING_BOOKINGS_LOADED,
            bookings: [job]
          });
          dispatch({
            type: actionTypes.PROVIDER_OPPORTUNITIES_LOADING,
            loading: false
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let opportunitiesErrors = {
          message: 'Something went wrong getting Opportunities'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            opportunitiesErrors = { message: data.error };
          } else {
            opportunitiesErrors = { ...data.error };
          }
        }
        if (response.status === 404) {
          opportunitiesErrors = {
            notFound: true,
            message: 'Opportunity not found'
          };
        }
        dispatch({
          type: actionTypes.PROVIDER_OPPORTUNITIES_ERRORS,
          errors: opportunitiesErrors
        });
      });
  };
}

export function resetOpportunityDetailFlag() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_OPPORTUNITY_LOADED_RESET_FLAG
    });
  };
}

export function addOpportunityFromEvent(eventData) {
  const startDate = moment(eventData.start_time_as_timestamp).format(
    'MM/DD/YYYY'
  );

  const opportunity = {
    opportunity_id: eventData.id,
    booking: {
      type: eventData.booking_type.toLowerCase(),
      job: {
        id: eventData.booking_id,
        status: eventData.booking_status,
        contact_name: eventData.contact_name,
        start_date: startDate,
        booking_time_window: eventData.booking_time_window,
        on_backup_list: false
      }
    }
  };

  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_OPPORTUNITY_ADDED,
      opportunity
    });
  };
}

export function updateOpportunityStatusFromEvent(eventData) {
  const startDate = moment(eventData.start_time_as_timestamp).format(
    'MM/DD/YYYY'
  );

  const opportunity = {
    opportunity_id: null,
    booking: {
      type: eventData.booking_type.toLowerCase(),
      job: {
        id: eventData.booking_id,
        status: eventData.booking_status,
        contact_name: eventData.contact_name,
        start_date: startDate,
        booking_time_window: eventData.booking_time_window,
        on_backup_list: eventData.on_backup_list
      }
    }
  };

  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_OPPORTUNITY_UPDATE,
      opportunity,
      bookingId: eventData.booking_id,
      bookingType: eventData.booking_type.toLowerCase()
    });
  };
}
