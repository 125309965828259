import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IonToolbar, IonFooter } from '@ionic/react';
import useViewport from 'hooks/useViewport';
import home from 'assets/images/iconImages/nanno-home.svg';
import searching from 'assets/images/iconImages/searching.svg';
import clock from 'assets/images/iconImages/clock.svg';
import shortlist from 'assets/images/iconImages/shortlist.svg';
import Icon from 'common/Icon';

const BottomNav = () => {
  const history = useHistory();
  const location = useLocation();
  const { width } = useViewport();
  const { pathname } = location;

  if (width > 640) return null;

  return (
    <IonFooter className="bottom-nav-container">
      <IonToolbar color="$primary">
        <div className="nav-buttons-container">
          <button
            className={pathname.indexOf('/dashboard') > -1 ? 'selected' : ''}
            type="button"
            onClick={() => history.push('/dashboard')}
          >
            <Icon icon={home} size="large" color="" />
            <h2>Home</h2>
          </button>
          <button
            className={pathname.indexOf('/providers') > -1 ? 'selected' : ''}
            type="button"
            onClick={() => history.push('/providers')}
          >
            <Icon icon={searching} size="large" color="" />
            <h2>Browse</h2>{' '}
          </button>
          <button
            className={pathname.indexOf('/bookings') > -1 ? 'selected' : ''}
            type="button"
            onClick={() => history.push('/bookings')}
          >
            <ion-icon icon={clock} size="large" color="" />
            <h2>Bookings</h2>{' '}
          </button>
          <button
            className={pathname.indexOf('/shortlists') > -1 ? 'selected' : ''}
            type="button"
            onClick={() => history.push('/shortlists')}
          >
            <Icon icon={shortlist} size="large" color="" />
            <h2>Shortlists</h2>
          </button>
          <button type="button" className="empty">
            <h2>&nbsp;</h2>
          </button>
        </div>
      </IonToolbar>
    </IonFooter>
  );
};

export default BottomNav;
