import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import callemmyAppReducer from './appReducer';

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState) => {
  return createStore(
    callemmyAppReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk))
  );
};
const store = configureStore({});

export default store;
