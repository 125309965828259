import actionTypes from 'constants/actionTypes';

const initialState = {
  creditCards: [],
  errors: {},
  clientSecret: null,
  loading: false,
  apiCalled: false,
  successfullyCreated: false,
  successfullyDeleted: false,
  getCreditCardsSuccessful: false
};

const creditCardReducer = (state = initialState, action) => {
  const creditCards = state.creditCards.slice(0);
  switch (action.type) {
    case actionTypes.CREDIT_CARD_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        apiCalled: true,
        successfullyCreated: false,
        successfullyDeleted: false
      };
    case actionTypes.CREDIT_CARD_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        successfullyCreated: false
      };
    case actionTypes.CREDIT_CARD_LOADED:
      return {
        ...state,
        loading: false,
        creditCards: action.creditCards,
        apiCalled: true,
        getCreditCardsSuccessful: true
      };
    case actionTypes.CREDIT_CARD_CREATE:
      return {
        ...state,
        loading: false,
        creditCards: creditCards.concat([action.creditCard]),
        successfullyCreated: true
      };
    case actionTypes.CREDIT_CARD_UPDATE:
      return {
        ...state,
        loading: false,
        creditCards: creditCards.map((creditCard) => {
          if (creditCard.id === action.creditCard.id) {
            return action.creditCard;
          }
          // Remove primary from other cards if the current was set as
          // primary
          if (action.creditCard.default) {
            const newCreditCard = { ...creditCard };
            newCreditCard.default = false;
            return newCreditCard;
          }
          return creditCard;
        })
      };
    case actionTypes.CREDIT_CARD_DELETE:
      return {
        ...state,
        loading: false,
        creditCards: creditCards.filter(
          (creditCard) => creditCard.id !== action.creditCardId
        ),
        successfullyDeleted: true
      };
    case actionTypes.CREDIT_CARD_STRIPE_CLIENT_SECRET:
      return {
        ...state,
        successfullyCreated: false,
        loading: false,
        errors: {},
        clientSecret: action.clientSecret
      };
    case actionTypes.CREDIT_CARD_RESET:
      return {
        ...state,
        loading: false,
        errors: {},
        successfullyCreated: false,
        successfullyDeleted: false
      };
    default:
      return state;
  }
};

export default creditCardReducer;
