import firebase from 'firebase/app';
import 'firebase/messaging';
import { Plugins } from '@capacitor/core';

const { PushNotifications } = Plugins;

let messaging = null;

const filterChatRoom = (data) => {
  let bookingId = null;
  const { channel: channelString } = data;
  const channel = JSON.parse(channelString);
  if (channel && channel.id) {
    const { id = '' } = channel;
    // eslint-disable-next-line
    const roomId = id.split('-');
    if (roomId && roomId.length > 0) {
      bookingId = roomId[roomId.length - 1];
    }
  }
  return bookingId;
};

function updateSubscriptionOnServer(subscription) {
  // TODO: Send subscription to application server

  const subscriptionJson = document.querySelector('.js-subscription-json');
  const subscriptionDetails = document.querySelector(
    '.js-subscription-details'
  );

  if (subscription && subscriptionJson) {
    subscriptionJson.textContent = JSON.stringify(subscription);
    subscriptionDetails.classList.remove('is-invisible');
  } else if (subscriptionDetails) {
    subscriptionDetails.classList.add('is-invisible');
  }
}

export function initializeUI(swRegistration) {
  // Set the initial subscription value
  swRegistration.pushManager.getSubscription().then(function(subscription) {
    const isSubscribed = !(subscription === null);
    updateSubscriptionOnServer(subscription);
    return isSubscribed;
  });
}

export function registerServiceWorker(callback) {
  if ('serviceWorker' in navigator) {
    console.log('Service Worker is supported');

    return navigator.serviceWorker
      .register('/custom-service-worker.js', { scope: '/' })
      .then(function(swReg) {
        console.log('Service Worker is registered', swReg);

        if (typeof callback === 'function') callback(swReg);

        return swReg;
      })
      .catch(function(error) {
        console.error('Service Worker Error', error);
      });
  }
  console.warn('Service worker is not supported');
  return null;
}

export function registerMessaging(callback) {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    console.log('Push is supported');

    return navigator.serviceWorker
      .getRegistrations()
      .then(function(response) {
        if (response && response.length > 0) {
          const swReg = response[0];
          console.log('Push is configured', swReg);

          messaging.useServiceWorker(swReg);
          if (typeof callback === 'function') callback(swReg);

          return swReg;
        }
        return null;
      })
      .catch(function(error) {
        console.error('Service Worker Error', error);
      });
  }
  console.warn('Push messaging is not supported');
  return null;
}

export function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

async function subscribeUser(callback) {
  try {
    await messaging.requestPermission();
    const token = await messaging.getToken();
    callback({
      token,
      subscribed: true
    });
    return token;
  } catch (error) {
    console.log({ error });
    callback({ subscribed: false });
    return error;
  }
}

export function toggleSubscribe(e, { swRegistration, callback }) {
  if (e && e.target) e.target.disabled = true;
  swRegistration.pushManager
    .getSubscription()
    .then(() => {
      subscribeUser(callback);
    })
    .catch(() => {
      subscribeUser(callback);
    });
}

export function initializeFirebase() {
  if (firebase.messaging.isSupported()) {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID
    };

    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      messaging = firebase.messaging();
      messaging.usePublicVapidKey(process.env.REACT_APP_VAPID_PUBLIC_KEY);
    } else {
      messaging = firebase.messaging();
    }
  }
}

export function setupPushNotificationsForIOS(
  sendUserToken,
  onAccept,
  onDenied
) {
  PushNotifications.removeAllListeners();
  // Push notifications event listeners
  PushNotifications.addListener('registration', (token) => {
    console.log(`Push registration success, token: ${token.value}`);
    sendUserToken(token.value);
  });
  // TODO: Send backend info that user has not registered for push
  // notifications
  PushNotifications.addListener('registrationError', (error) => {
    console.log(`Error on registration: ${JSON.stringify(error)}`);
  });
  // When a push notification is received
  // PushNotifications.addListener('pushNotificationReceived', (notification) => {
  //   alert(`Push received: ${JSON.stringify(notification)}`);
  // });

  // Method called when tapping on a notification
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notificationObject) => {
      const { notification: notificationData } = notificationObject || {};
      const { data = {} } = notificationData || {};
      const bookingId = filterChatRoom(data);
      let actions = [];
      if (bookingId) {
        actions.push({
          action: `open_booking_chat_link_${bookingId}`,
          title: 'Open chat'
        });
      } else {
        const actionsString = data['gcm.notification.actions'];
        actions = actionsString ? JSON.parse(actionsString) : [];
      }
      if (actions.length === 1) {
        const action = actions[0];
        const actionName = action.action;
        const url = `${window.location.origin}/push-action?action_name=${actionName}`;
        window.location = url;
      }
    }
  );

  // Request permission for push notifications
  PushNotifications.requestPermission().then((result) => {
    if (result.granted) {
      console.log('registering permission');
      PushNotifications.register();
      if (onAccept && typeof onAccept === 'function') onAccept();
    } else {
      console.log('Push notifications not allowed');
      if (onDenied && typeof onDenied === 'function') onDenied();
    }
  });
}
