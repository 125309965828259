import Jsona from 'jsona';
import actionTypes from 'constants/actionTypes';
import { api } from '../../api/connect';

const dataFormatter = new Jsona();

export function getProviders(
  { limit = 15, zipcode, offset = 0, variant = '' },
  callback = () => null
) {
  return (dispatch) => {
    if (offset === 0) {
      dispatch({
        type: actionTypes.PROVIDER_RESET
      });
    }
    dispatch({
      type: actionTypes.PROVIDER_LOADING,
      loading: true
    });

    api()
      .get(
        `/providers?limit=${limit}&zipcode=${zipcode}&offset=${offset}&variant=${variant}`
      )
      .then((response) => {
        const { data } = response;
        let providers = [];
        if (variant === 'full') {
          providers = data.providers || [];
        } else {
          providers = dataFormatter.deserialize(data);
        }
        dispatch({
          type: actionTypes.PROVIDER_LOADED,
          providers
        });
        callback(providers);
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let providerErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            providerErrors = { message: data.error };
          } else {
            providerErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_ERRORS,
          errors: providerErrors
        });
      });
  };
}

export function getProvidersBackground({
  limit = 100,
  zipcode,
  offset = 0,
  variant
}) {
  return (dispatch) => {
    api()
      .get(
        `/providers?limit=${limit}&zipcode=${zipcode}&offset=${offset}&variant=${variant}`
      )
      .then((response) => {
        const { data } = response;
        let providers = [];
        if (variant === 'full') {
          providers = data.providers || [];
        } else {
          providers = dataFormatter.deserialize(data);
        }
        dispatch({
          type: actionTypes.PROVIDER_LOADED,
          providers
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let providerErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            providerErrors = { message: data.error };
          } else {
            providerErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_ERRORS,
          errors: providerErrors
        });
      });
  };
}

export function getProvider(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_LOADING,
      loading: true
    });

    api()
      .get(`/providers/${id}`)
      .then((response) => {
        const { data } = response || {};
        const { provider } = data || [];
        dispatch({
          type: actionTypes.PROVIDER_UPDATE,
          provider
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let providerErrors = { message: 'Something went wrong' };
        if (response.status === 404) {
          providerErrors = { not_found: true };
        }
        if (response.status !== 404 && data.error) {
          if (typeof data.error === 'string') {
            providerErrors = { message: data.error };
          } else {
            providerErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_ERRORS,
          errors: providerErrors
        });
      });
  };
}

export function getProviderReviews(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_LOADING,
      loading: true
    });

    api()
      .get(`/reviews?reviewee_id=${id}&reviewee_type=Provider`)
      .then((response) => {
        const { data } = response || {};
        const { reviews } = data || {};
        dispatch({
          type: actionTypes.PROVIDER_REVIEWS,
          providerId: id,
          reviews: {
            rating: reviews.average_rating,
            records: reviews.review_records
          }
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let providerErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            providerErrors = { message: data.error };
          } else {
            providerErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_ERRORS,
          errors: providerErrors
        });
      });
  };
}

export function loadProvidersFromStorage(providers) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_LOADING,
      loading: true
    });
    dispatch({
      type: actionTypes.PROVIDER_LOADED_FROM_STORAGE,
      providers: { ...providers }
    });
  };
}

export function updateProviderFilters(filters) {
  return (dispatch) => {
    dispatch({
      filters,
      type: actionTypes.PROVIDER_FILTERS
    });
  };
}

export function resetProviders() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_RESET
    });
  };
}

export function resetProvidersErrors() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_RESET_ERRORS
    });
  };
}
