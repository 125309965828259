import store from 'store/store';
import {
  addOpportunityFromEvent,
  updateOpportunityStatusFromEvent
} from 'provider/actions/opportunityActions';
import { updateBooking } from 'provider/actions/bookingActions';

const eventAction = (channel, event, action) => {
  channel.bind(event, action);
};

export default function channelEventsListener({ channel, user }) {
  eventAction(channel, 'add-opportunity', function(data) {
    store.dispatch(addOpportunityFromEvent(data));
  });
  eventAction(channel, 'opportunity-taken', function(data) {
    store.dispatch(updateOpportunityStatusFromEvent(data));
  });
  eventAction(channel, 'booking-update', function(data) {
    if (user.role !== 'booker') {
      store.dispatch(updateBooking(data));
    }
  });
}
