import moment from 'dayjs';
import actionTypes from 'constants/actionTypes';

const initialState = {
  providers: [],
  providersById: {},
  reviews: {},
  errors: {},
  loading: false,
  providersLoaded: false,
  filtersSelected: {}
};

const updateProviders = (providers, newProviders = []) => {
  const newProvidersArray = newProviders.slice(0);
  const updatedProviders = providers.map((provider) => {
    const newProviderIndex = newProvidersArray.findIndex(
      (s) => s.id === provider.id
    );
    if (newProviderIndex > -1) {
      return newProvidersArray.splice(newProviderIndex, 1)[0];
    }
    return provider;
  });

  // Sort by approved_on
  return updatedProviders.concat(newProvidersArray).sort((a, b) => {
    if (moment(a.approved_on).isBefore(b.approved_on)) {
      return 1;
    }
    return -1;
  });
};

const providerReducer = (state = initialState, action) => {
  const providersById = { ...state.providersById };
  const reviews = { ...state.reviews };
  switch (action.type) {
    case actionTypes.PROVIDER_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {}
      };
    case actionTypes.PROVIDER_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors
      };
    case actionTypes.PROVIDER_RESET:
      return {
        ...state,
        providers: [],
        providersById: {},
        providersLoaded: false
      };
    case actionTypes.PROVIDER_RESET_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case actionTypes.PROVIDER_LOADED:
      action.providers.forEach((provider) => {
        providersById[provider.id] = provider;
      });
      return {
        ...state,
        loading: false,
        providersById: { ...providersById },
        providers: updateProviders(state.providers, action.providers),
        providersLoaded: true
      };
    case actionTypes.PROVIDER_LOADED_FROM_STORAGE:
      return {
        ...state,
        loading: false,
        providersById: { ...action.providers },
        providers: updateProviders(
          state.providers,
          Object.values(action.providers)
        ),
        providersLoaded: Object.values(action.providers).length > 0
      };
    case actionTypes.PROVIDER_UPDATE:
      providersById[action.provider.id] = { ...action.provider };
      return {
        ...state,
        loading: false,
        providersById: { ...providersById },
        providers: updateProviders(state.providers, [action.provider])
      };
    case actionTypes.PROVIDER_FILTERS:
      return {
        ...state,
        loading: false,
        filtersSelected: { ...action.filters }
      };
    case actionTypes.PROVIDER_REVIEWS:
      reviews[action.providerId] = { ...action.reviews };
      return {
        ...state,
        loading: false,
        reviews: { ...reviews }
      };
    default:
      return state;
  }
};

export default providerReducer;
