import React, { useState, useEffect, useRef, cloneElement } from 'react';
import useViewport from 'hooks/useViewport';

const Popover = ({ children, button, color = 'white', width }) => {
  const [showPopover, setShowPopover] = useState(false);
  const targetElement = useRef(null);
  const popoverElement = useRef(null);
  const { width: screenWidth } = useViewport();

  useEffect(() => {
    if (showPopover) {
      const targetRect =
        targetElement.current.firstChild.getBoundingClientRect() || {};
      const popoverRect = popoverElement.current.getBoundingClientRect() || {};
      popoverElement.current.style.left = `${targetRect.left -
        popoverRect.width +
        targetRect.width / 2 +
        29}px`;
      popoverElement.current.style.top = `${targetRect.top -
        window.scrollY +
        targetRect.height +
        20}px`;
      popoverElement.current.style.visibility = 'visible';
    }
  }, [showPopover, screenWidth]);

  // Needed in order to set onClick attribute to the button
  const clonedButton = cloneElement(button, {
    onClick: () => setShowPopover(!showPopover),
    reference: targetElement
  });

  return (
    <React.Fragment>
      {showPopover && (
        <>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="popover-backdrop"
            onClick={() => setShowPopover(false)}
          />
          <div
            ref={popoverElement}
            style={{ width, backgroundColor: color }}
            className="popover"
          >
            <span
              style={{ backgroundColor: color }}
              className="popover-arrow"
            />
            {React.cloneElement(children, { setShowPopover })}
          </div>
        </>
      )}
      {clonedButton}
    </React.Fragment>
  );
};

export default Popover;
