import React, { useState } from 'react';
import { IonCard, IonCardContent, IonCardTitle } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import Icon from './Icon';
import Button from './Button';

const Information = ({
  className = '',
  type = 'warning',
  title,
  children,
  icon,
  size,
  showCloseIcon = false
}) => {
  const [show, setShow] = useState(true);
  if (!show) return null;

  return (
    <IonCard
      style={{ flexDirection: title ? 'column' : 'row' }}
      className={`information-box ${className} ${type} ${
        title ? 'wrap-it' : ''
      }`}
    >
      {title && (
        <div className="info-header">
          {icon && <Icon icon={icon} size={size} />}
          <IonCardTitle className={icon ? 'has-icon' : ''}>
            {title}
          </IonCardTitle>
          {showCloseIcon && (
            <Button
              className="button-link info-header__close-button"
              onClick={() => setShow(false)}
            >
              <Icon icon={closeCircle} slot="icon-only" />
            </Button>
          )}
        </div>
      )}
      {!title && icon && (
        <span style={{ marginRight: '0.5em' }}>
          <Icon icon={icon} size={size} />
        </span>
      )}
      <IonCardContent>{children}</IonCardContent>
    </IonCard>
  );
};

export default Information;
