import React from 'react';
import PropTypes from 'prop-types';
import { IonLabel } from '@ionic/react';

const Label = ({ children, className = '', color, style }) => {
  return (
    <IonLabel
      color={color}
      className={`callemmy-label ${className}`}
      style={style}
    >
      {children}
    </IonLabel>
  );
};

/* eslint-disable react/forbid-prop-types */
Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
};

export default Label;
