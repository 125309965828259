import axios from 'axios';

/* eslint no-nested-ternary: 0 */
const server = process.env.REACT_APP_NANNO_API_ROOT;
const VERSION = 'v2';
const baseURL = `${server}${VERSION}`;

const loginInstance = axios.create({ baseURL });
const apiInstance = axios.create({ baseURL });

export const api = (version = VERSION) => {
  const newBaseURL = `${server}${version}`;
  if (baseURL !== newBaseURL)
    apiInstance.defaults.baseURL = `${server}${version}`;
  return apiInstance;
};

export const login = (version = VERSION) => {
  const newBaseURL = `${server}${version}`;
  if (baseURL !== newBaseURL)
    loginInstance.defaults.baseURL = `${server}${version}`;
  return loginInstance;
};

// Add a request interceptor for api
api().interceptors.request.use(
  (config) => {
    const callemmyAuth = JSON.parse(window.localStorage.getItem('user')) || {};
    if (callemmyAuth && callemmyAuth.token) {
      const newConfig = config;
      const { headers } = newConfig;
      newConfig.headers = {
        ...headers,
        'X-User-Uuid': callemmyAuth.uuid,
        'X-User-Token': callemmyAuth.token
      };
      return newConfig;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor for others api requests
api().interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error && error.response ? error.response : 0;
    if (status === 401) {
      window.localStorage.setItem('user', null);
      window.location.href = '/sign-in';
      return null;
    }
    return Promise.reject(error);
  }
);

// Add a response interceptor for login
login().interceptors.response.use(
  (response) => {
    const { data } = response;
    const user = {
      ...data,
      token: data.auth_token
    };
    window.localStorage.setItem('user', JSON.stringify(user));
    return response;
  },
  (error) => Promise.reject(error)
);
