// eslint-disable-next-line
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Plugins } from '@capacitor/core';

const { App } = Plugins;

const AppUrlListener = () => {
  const history = useHistory();
  useEffect(() => {
    App.addListener('appUrlOpen', (data) => {
      const { url = '' } = data;
      const urlDivided = url.split('callemmy.com/');
      const params = urlDivided.length > 0 ? urlDivided[1] : '';
      history.push(`/${params}`);
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
