import { combineReducers } from 'redux';
import actionTypes from 'constants/actionTypes';
import bookerRootReducer from 'booker/reducers/rootReducer';
import providerRootReducer from 'provider/reducers/rootReducer';
import commonRootReducer from 'common/reducers/rootReducer';

const callemmyAppReducer = (state, action) => {
  const providerReducer = combineReducers({
    ...providerRootReducer
  });

  const allReducers = combineReducers({
    ...bookerRootReducer,
    ...commonRootReducer,
    provider: providerReducer
  });

  // When user logs out it reset redux state returning it undefined
  if (action.type === actionTypes.ERASE_STORE_DATA) {
    return allReducers(undefined, action);
  }

  return allReducers(state, action);
};

export default callemmyAppReducer;
