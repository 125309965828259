import actionTypes from 'constants/actionTypes';

const initialState = {
  familySettings: {},
  errors: {},
  loading: false,
  apiCalled: false,
  successfullyUpdated: false
};

const familySettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FAMILY_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        apiCalled: true,
        successfullyUpdated: false
      };
    case actionTypes.FAMILY_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        successfullyUpdated: false
      };
    case actionTypes.FAMILY_LOADED:
      return {
        ...state,
        loading: false,
        familySettings: action.familySettings,
        apiCalled: true
      };
    case actionTypes.FAMILY_UPDATE:
      return {
        ...state,
        loading: false,
        familySettings: { ...action.familySettings },
        successfullyUpdated: true
      };
    default:
      return state;
  }
};

export default familySettingsReducer;
