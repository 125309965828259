import actionTypes from 'constants/actionTypes';

export function addToast(params) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOAST_ADD,
      params
    });
  };
}

export function removeToast(id) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.TOAST_REMOVE,
      id
    });
  };
}
