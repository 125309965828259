import actionTypes from 'constants/actionTypes';

// Test Example
// const test = {
//   type: 'type',
//   currentQuestion: 0
// }

const initialState = {
  currentTest: 0,
  tests: []
};

const skillsTestsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SKILLS_TESTS_SET_TESTS: {
      return {
        ...state,
        tests: action.tests
      };
    }
    case actionTypes.SKILLS_TESTS_SET_CURRENT_QUESTION: {
      const updatedTests = state.tests.map((t) => {
        const newTest = t;

        if (t.type === action.test) {
          newTest.currentQuestion = action.questionNumber;
        }

        return newTest;
      });

      return {
        ...state,
        tests: updatedTests
      };
    }
    default:
      return state;
  }
};

export default skillsTestsReducer;
