import React from 'react';
import { IonIcon } from '@ionic/react';

const Icon = ({ icon, size, slot, src, color, className = '' }) => {
  return (
    <IonIcon
      icon={icon}
      size={size}
      slot={slot}
      src={src}
      color={color}
      className={`callemmy-icon ${className}`}
    />
  );
};

export default Icon;
