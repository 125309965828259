import actionTypes from 'constants/actionTypes';

const initialState = {
  subscription: {},
  plans: [],
  inquiry: {},
  errors: {},
  loading: false,
  apiCalled: false,
  successfullyUpdated: false,
  inquirySuccessfullyCreated: false
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBSCRIPTION_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        apiCalled: true,
        successfullyUpdated: false
      };
    case actionTypes.SUBSCRIPTION_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        successfullyUpdated: false
      };
    case actionTypes.SUBSCRIPTION_PLANS_LOADED:
      return {
        ...state,
        loading: false,
        plans: action.plans,
        apiCalled: true
      };
    case actionTypes.SUBSCRIPTION_LOADED:
      return {
        ...state,
        loading: false,
        subscription: action.subscription,
        apiCalled: true
      };
    case actionTypes.SUBSCRIPTION_UPDATE:
      return {
        ...state,
        loading: false,
        errors: {},
        subscription: { ...action.subscription },
        successfullyUpdated: true
      };
    case actionTypes.SUBSCRIPTION_RESET_SUCCESSFULLY_UPDATED:
      return {
        ...state,
        successfullyUpdated: false
      };
    case actionTypes.SUBSCRIPTION_INQUIRY_CREATE:
      return {
        ...state,
        loading: false,
        inquiry: action.inquiry,
        inquirySuccessfullyCreated: true
      };
    case actionTypes.SUBSCRIPTION_INQUIRY_MODAL_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        inquirySuccessfullyCreated: false
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
