import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation, withRouter } from 'react-router-dom';
import { menuOutline, personOutline } from 'ionicons/icons';
import { IonMenuToggle, IonToolbar, IonTitle, IonButtons } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { userLogout } from 'booker/actions/userActions';
import { updateBooking } from 'booker/actions/bookingActions';
import { cloneShortlist } from 'booker/actions/shortlistActions';
import desktopLogo from 'assets/images/desktop-logo-small.webp';
import useViewport from 'hooks/useViewport';
import moment from 'dayjs';
import Icon from 'common/Icon';
import Button from 'common/Button';
import Popover from 'common/Popover';

const MyAccountDesktop = ({ history, logout, setShowPopover }) => {
  return (
    <ul className="desktop-my-account-menu">
      <li className="title">My account</li>
      <li>
        <Button
          id="account__btn--subscription"
          className="button-link"
          onClick={() => {
            setShowPopover(false);
            history.push('/subscription');
          }}
        >
          Manage Subscription
        </Button>
      </li>
      <li>
        <Button
          id="account__btn--settings"
          className="button-link"
          onClick={() => {
            setShowPopover(false);
            history.push('/family-settings');
          }}
        >
          Family Info
        </Button>
      </li>
      <li>
        <Button
          id="account__btn--details"
          className="button-link"
          onClick={() => {
            setShowPopover(false);
            history.push('/my-details');
          }}
        >
          Account Info
        </Button>
      </li>
      <li>
        <Button
          id="account__btn--log-out"
          className="button-link"
          onClick={() => {
            setShowPopover(false);
            logout();
          }}
        >
          Log Out
        </Button>
      </li>
    </ul>
  );
};

const MainMenuDesktop = ({ history, setShowPopover }) => {
  return (
    <ul className="desktop-my-account-menu">
      <li className="title">Help &amp; Support</li>
      <li>
        <Button
          className="button-link"
          onClick={() => {
            setShowPopover(false);
            history.push('/faq');
          }}
        >
          FAQs
        </Button>
      </li>
      <li>
        <Button
          className="button-link"
          href="mailto:support@callemmy.com"
          onClick={() => {
            setShowPopover(false);
          }}
        >
          Contact Call Emmy
        </Button>
      </li>
      <li className="title">Learn More</li>
      <li>
        <Button
          className="button-link"
          onClick={() => history.push('/services')}
        >
          Our Services
        </Button>
      </li>
      <li>
        <Button className="button-link" onClick={() => history.push('/safety')}>
          Screening Process
        </Button>
      </li>
      <li>
        <Button
          className="button-link"
          onClick={() => history.push('/my-promise')}
        >
          Our Promise
        </Button>
      </li>
      <li className="title">About</li>
      <li>
        <Button
          className="button-link"
          onClick={() => history.push('/our-story')}
        >
          Our Story
        </Button>
      </li>
      <li>
        <Button className="button-link" href="https://callemmy.com/team/">
          Meet the team
        </Button>
      </li>
    </ul>
  );
};

const mobileTop = ({ showMenu, title, fullPage }) => {
  if (fullPage) return null;

  return (
    <>
      <IonToolbar
        className={`callemmy-toolbar top-nav ${
          Capacitor && Capacitor.platform === 'web' ? 'top-nav-web' : ''
        }`}
        color="secondary"
      >
        {showMenu && (
          <>
            <IonButtons className="left-menu-button" slot="start">
              <IonMenuToggle
                menu="my-account-menu"
                autoHide={false}
                id="account-menu"
              >
                <Button
                  id="top-nav__btn--account"
                  slot="start"
                  fill="clear"
                  size="small"
                  className="toggle-menu-button"
                >
                  <Icon icon={personOutline} slot="icon-only" />
                </Button>
              </IonMenuToggle>
            </IonButtons>
            <IonButtons className="right-menu-button" slot="end">
              <IonMenuToggle menu="main-menu" autoHide={false} id="main-menu">
                <Button
                  id="top-nav__btn--menu"
                  slot="end"
                  fill="clear"
                  size="small"
                  className="toggle-menu-button"
                >
                  <Icon icon={menuOutline} slot="icon-only" />
                </Button>
              </IonMenuToggle>
            </IonButtons>
          </>
        )}
        <IonTitle className="title">{title}</IonTitle>
      </IonToolbar>
    </>
  );
};

const desktopTop = ({
  title,
  history,
  authenticated,
  logout,
  booking,
  dispatch,
  shortlistId,
  errorPage,
  location,
  navigationProps
}) => {
  const hasStartedBooking =
    booking.startedAt && moment().diff(moment(booking.startedAt), 'hours') < 24;
  const { showButtonCopyToMyCallEmmy } = navigationProps;
  const myAccountButton = (
    <Button
      id="top-nav__btn--account"
      className="desktop-my-account-button"
      fill="outline"
    >
      <Icon icon={personOutline} slot="icon-only" />
    </Button>
  );
  const mainMenuButton = (
    <Button id="top-nav__btn--menu" className="desktop-menu-button">
      <Icon icon={menuOutline} slot="icon-only" />
    </Button>
  );
  const dashboard = title === 'Dashboard';

  return (
    <header
      className={`${
        dashboard || authenticated || showButtonCopyToMyCallEmmy || errorPage
          ? 'authenticated-desktop-top-nav'
          : ''
      } desktop-top-nav`}
    >
      <div className="desktop-top-nav-container">
        <a
          href="https://www.callemmy.com"
          className="logo-link"
          id="top-nav__link--logo"
        >
          <img className="desktop-logo" src={desktopLogo} alt="logo" />
        </a>
        <div className="menu-links">
          <Link to="/how-it-works">How It Works</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/safety">Safety</Link>
          {!authenticated && (
            <>
              <Button
                className="authentication-buttons desktop-signup"
                fill="outline"
                onClick={() => history.push(`/sign-up${location.search}`)}
              >
                Sign up
              </Button>
              <Button
                className="authentication-buttons desktop-signin"
                onClick={() => history.push(`/sign-in${location.search}`)}
              >
                Sign in
              </Button>
            </>
          )}
          {authenticated && (
            <>
              <Popover button={myAccountButton}>
                <MyAccountDesktop logout={logout} history={history} />
              </Popover>
              <Popover button={mainMenuButton}>
                <MainMenuDesktop history={history} />
              </Popover>
            </>
          )}
        </div>
      </div>
      {!authenticated && !errorPage && (
        <div
          className={
            dashboard || showButtonCopyToMyCallEmmy
              ? 'page-title-with-button'
              : 'page-title'
          }
        >
          {!dashboard && <h1>{title}</h1>}
          {dashboard && (
            <Button
              id="top-nav__btn--booking"
              className="desktop-make-booking"
              onClick={() => {
                if (hasStartedBooking) {
                  history.push(`/booking/${booking.step}`);
                } else {
                  history.push('/booking/1');
                }
              }}
            >
              {hasStartedBooking ? 'Return to booking' : 'Book a provider'}
            </Button>
          )}
          {showButtonCopyToMyCallEmmy && (
            <Button
              id="top-nav__btn--booking"
              className="desktop-make-booking"
              onClick={() => {
                if (authenticated) {
                  dispatch(
                    cloneShortlist(shortlistId, (clonedShortlist) => {
                      history.push(`/shortlists/${clonedShortlist.uuid}`);
                    })
                  );
                } else {
                  history.push(
                    `/sign-in?next=copy-shortlist&shortlistId=${shortlistId}`
                  );
                }
              }}
            >
              Copy to my Call Emmy
            </Button>
          )}
        </div>
      )}
    </header>
  );
};

const TopNav = ({
  title,
  showMenu = true,
  authenticated,
  match,
  fullPage,
  showMakeBookingButton,
  errorPage,
  fullTopImage
}) => {
  const { width } = useViewport();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { params } = match;
  const { uuid: shortlistId } = params;
  const logout = () => {
    dispatch(userLogout(() => history.push('/sign-in')));
  };

  const { booking } = useSelector((state) => state.bookingReducer);
  const bookingShortlists = booking.shortlists || [];
  const selectShortlist = () => {
    const updatedBookingShortlists = bookingShortlists.slice(0);
    const index = updatedBookingShortlists.indexOf(shortlistId);
    if (index === -1) {
      updatedBookingShortlists.push(shortlistId);
      dispatch(
        updateBooking({ ...booking, shortlists: updatedBookingShortlists })
      );
    }
  };
  const { props: navigationProps } = useSelector(
    (state) => state.navigationReducer
  );

  return width > 640
    ? desktopTop({
        fullPage,
        title,
        history,
        authenticated,
        logout,
        showMakeBookingButton,
        booking,
        dispatch,
        shortlistId,
        selectShortlist,
        errorPage,
        location,
        navigationProps
      })
    : mobileTop({
        title,
        showMenu,
        fullPage,
        booking,
        authenticated,
        showMakeBookingButton,
        history,
        shortlistId,
        dispatch,
        selectShortlist,
        fullTopImage,
        navigationProps
      });
};

export default withRouter(TopNav);
