import actionTypes from 'constants/actionTypes';

const initialState = {
  props: {}
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NAVIGATION_SET_PROPS:
      return {
        ...state,
        props: action.props
      };
    case actionTypes.NAVIGATION_DELETE_PROPS:
      return {
        ...state,
        props: {}
      };
    default:
      return state;
  }
};

export default navigationReducer;
