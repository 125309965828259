import actionTypes from 'constants/actionTypes';

const initialState = {
  addresses: [],
  errors: {},
  loading: false,
  apiCalled: false,
  successfullyCreated: false,
  successfullyDeleted: false,
  getProviderAddressesSuccessful: false
};

const addressReducer = (state = initialState, action) => {
  const addresses = state.addresses.slice(0);
  switch (action.type) {
    case actionTypes.PROVIDER_ADDRESS_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        apiCalled: true,
        successfullyCreated: false,
        successfullyDeleted: false
      };
    case actionTypes.PROVIDER_ADDRESS_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        successfullyCreated: false
      };
    case actionTypes.PROVIDER_ADDRESS_RESET:
      return {
        ...state,
        loading: false,
        errors: {},
        successfullyCreated: false,
        successfullyDeleted: false
      };
    case actionTypes.PROVIDER_ADDRESS_LOADED:
      return {
        ...state,
        loading: false,
        addresses: action.addresses,
        apiCalled: true,
        getProviderAddressesSuccessful: true
      };
    case actionTypes.PROVIDER_ADDRESS_CREATE:
      return {
        ...state,
        loading: false,
        addresses: addresses.concat([action.address]),
        successfullyCreated: true
      };
    case actionTypes.PROVIDER_ADDRESS_UPDATE:
      return {
        ...state,
        loading: false,
        addresses: addresses.map((address) => {
          if (address.id === action.address.id) {
            return action.address;
          }
          // Remove primary from other addresses if the current was set as
          // primary
          if (action.address.primary) {
            const newAddress = { ...address };
            newAddress.primary = false;
            return newAddress;
          }
          return address;
        })
      };
    case actionTypes.PROVIDER_ADDRESS_DELETE:
      return {
        ...state,
        loading: false,
        addresses: addresses.filter(
          (address) => address.id !== action.addressId
        ),
        successfullyDeleted: true
      };
    default:
      return state;
  }
};

export default addressReducer;
