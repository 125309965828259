import actionTypes from 'constants/actionTypes';

const initialState = {
  toasts: []
};

const toastReducer = (state = initialState, action) => {
  const toasts = state.toasts.slice(0);
  switch (action.type) {
    case actionTypes.TOAST_ADD:
      toasts.push({ ...action.params, id: Date.now() });
      return {
        ...state,
        toasts
      };
    case actionTypes.TOAST_REMOVE:
      return {
        ...state,
        toasts: toasts.filter((toast) => toast.id !== action.id)
      };
    default:
      return state;
  }
};

export default toastReducer;
