import actionTypes from 'constants/actionTypes';

const initialState = {
  opportunities: {
    upcoming: {
      count: 0,
      opportunity_records: []
    },
    past: {
      count: 0,
      opportunity_records: []
    }
  },
  allOpportunities: [],
  opportunityDetail: {},
  errors: {},
  loading: false,
  apiCalled: false,
  successfullyLoadedOpportunitySummary: false,
  opportunityDetailGetSuccessful: false,
  upcomingOpportunitiesSuccessfullyLoaded: false,
  pastOpportunitiesSuccessfullyLoaded: false,
  assignedOpportunityFlag: false
};

const getAllOpportunityRecords = (opportunities) => {
  const { upcoming } = opportunities;
  return upcoming.opportunity_records;
};

const loadSummaryOpportunities = (opportunities, opportunitySumary) => {
  let newOpportunities = { ...opportunities };
  const { upcoming = {} } = opportunitySumary;

  if (upcoming.opportunity_records && upcoming.opportunity_records.length > 0) {
    let updatedUpcomingOpportunities = opportunities.upcoming.opportunity_records.filter(
      (opportunity) =>
        !upcoming.opportunity_records.find(
          (b) => b.opportunity_id === opportunity.opportunity_id
        )
    );
    updatedUpcomingOpportunities = updatedUpcomingOpportunities.concat(
      upcoming.opportunity_records
    );
    newOpportunities = {
      ...newOpportunities,
      upcoming: {
        count: upcoming.count,
        opportunity_records: updatedUpcomingOpportunities
      }
    };
  }
  return newOpportunities;
};

const loadSingleTypeOpportunities = (opportunities, newOpportunities, type) => {
  let updatedOpportunities = { ...opportunities };

  if (type === 'past') {
    let updatedPastOpportunities = opportunities.past.opportunity_records.filter(
      (opportunity) =>
        !newOpportunities.find(
          (b) => b.opportunity_id === opportunity.opportunity_id
        )
    );
    updatedPastOpportunities = updatedPastOpportunities.concat(
      newOpportunities
    );
    updatedOpportunities = {
      ...updatedOpportunities,
      past: {
        ...updatedOpportunities.past,
        opportunity_records: updatedPastOpportunities
      }
    };
  }

  if (type === 'upcoming') {
    let updatedUpcomingOpportunities = opportunities.upcoming.opportunity_records.filter(
      (opportunity) =>
        !newOpportunities.find(
          (b) => b.opportunity_id === opportunity.opportunity_id
        )
    );
    updatedUpcomingOpportunities = updatedUpcomingOpportunities.concat(
      newOpportunities
    );
    updatedOpportunities = {
      ...updatedOpportunities,
      upcoming: {
        ...updatedOpportunities.upcoming,
        opportunity_records: updatedUpcomingOpportunities
      }
    };
  }
  return updatedOpportunities;
};

const removeOpportunity = (
  opportunities = {},
  opportunityId,
  type = 'upcoming'
) => {
  if (opportunities[type] && opportunities[type].opportunity_records) {
    const newOpportunities = { ...opportunities };
    const newOpportunityRecords = opportunities[
      type
    ].opportunity_records.filter(
      (op) => op.opportunity_id !== Number(opportunityId)
    );
    newOpportunities[type].opportunity_records = newOpportunityRecords;
    newOpportunities[type].count -= 1;
    return newOpportunities;
  }
  return opportunities;
};

const updateOpportunity = (opportunities, newOpportunity) => {
  const types = Object.keys(opportunities) || [];
  const newOpportunities = { ...opportunities };
  types.forEach((type) => {
    const updatedRecords = newOpportunities[type].opportunity_records.map(
      (opportunity) => {
        if (
          opportunity.opportunity_id === newOpportunity.id &&
          newOpportunity.booking_type
        ) {
          const bookingType = newOpportunity.booking_type.toLowerCase();
          return {
            ...opportunity,
            booking: {
              ...opportunity.booking,
              [bookingType]: {
                ...opportunity.booking[bookingType],
                on_backup_list: newOpportunity.on_backup_list,
                status: newOpportunity.booking_status
              }
            }
          };
        }
        return opportunity;
      }
    );
    newOpportunities[type].opportunity_records = updatedRecords;
  });
  return newOpportunities;
};

const updateOpportuntityStatusByBookingId = ({
  inititalOpportunityRecords,
  newOpportunity,
  bookingId,
  bookingType
}) => {
  const newOpportunityRecords = [...inititalOpportunityRecords];
  return newOpportunityRecords.map((opp) => {
    if (opp.booking[bookingType].id === bookingId) {
      return {
        ...opp,
        booking: {
          ...opp.booking,
          [bookingType]: {
            ...opp.booking[bookingType],
            status: newOpportunity.booking[bookingType].status
          }
        }
      };
    }
    return opp;
  });
};

const addOpportunity = (opportunities, opportunity, type = 'upcoming') => {
  const updatedOpportunities = { ...opportunities };
  const opportunityExists =
    opportunities[type] &&
    opportunities[type].opportunity_records &&
    opportunities[type].opportunity_records.findIndex(
      (op) => op.opportunity_id === opportunity.opportunity_id
    ) > -1;
  if (opportunityExists) {
    const updatedRecords = opportunities[type].opportunity_records.filter(
      (op) => {
        if (op.opportunity_id === opportunity.opportunity_id) {
          return opportunity;
        }
        return op;
      }
    );
    updatedOpportunities[type].opportunity_records = updatedRecords;
  } else {
    updatedOpportunities[type].opportunity_records.push(opportunity);
  }
  updatedOpportunities[type].count += 1;
  return updatedOpportunities;
};

const opportunitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROVIDER_OPPORTUNITIES_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.PROVIDER_OPPORTUNITIES_ERRORS:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };
    case actionTypes.PROVIDER_OPPORTUNITIES_SUMMARY_LOADED:
      return {
        ...state,
        opportunities: loadSummaryOpportunities(
          state.opportunities,
          action.opportunities
        ),
        allOpportunities: getAllOpportunityRecords(action.opportunities),
        loading: false,
        apiCalled: true,
        successfullyLoadedOpportunitySummary: true
      };
    case actionTypes.PROVIDER_OPPORTUNITIES_LOADED:
      return {
        ...state,
        opportunities: loadSingleTypeOpportunities(
          state.opportunities,
          action.opportunities,
          'upcoming'
        ),
        loading: false,
        apiCalled: true,
        upcomingOpportunitiesSuccessfullyLoaded: true
      };
    case actionTypes.PROVIDER_PAST_OPPORTUNITIES_LOADED:
      return {
        ...state,
        opportunities: loadSingleTypeOpportunities(
          state.opportunities,
          action.opportunities,
          'past'
        ),
        loading: false,
        apiCalled: true,
        pastOpportunitiesSuccessfullyLoaded: true
      };
    case actionTypes.PROVIDER_OPPORTUNITY_LOADED:
      return {
        ...state,
        loading: false,
        opportunityDetail: action.opportunity,
        opportunities: updateOpportunity(
          state.opportunities,
          action.opportunity
        ),
        apiCalled: true,
        opportunityDetailGetSuccessful: true
      };
    case actionTypes.PROVIDER_OPPORTUNITY_ADDED:
      return {
        ...state,
        opportunities: addOpportunity(
          state.opportunities,
          action.opportunity,
          'upcoming'
        )
      };
    case actionTypes.PROVIDER_OPPORTUNITY_UPDATE:
      return {
        ...state,
        opportunities: {
          ...state.opportunities,
          upcoming: {
            ...state.opportunities.upcoming,
            opportunity_records: updateOpportuntityStatusByBookingId({
              inititalOpportunityRecords:
                state.opportunities.upcoming.opportunity_records,
              newOpportunity: action.opportunity,
              bookingId: action.bookingId,
              bookingType: action.bookingType
            })
          }
        }
      };
    case actionTypes.PROVIDER_OPPORTUNITY_ASSIGNMENT_LOADED:
      return {
        ...state,
        loading: false,
        assignedOpportunityFlag: true
      };
    case actionTypes.PROVIDER_OPPORTUNITY_LOADED_RESET_FLAG:
      return {
        ...state,
        assignedOpportunityFlag: false,
        opportunityDetailGetSuccessful: false
      };
    case actionTypes.PROVIDER_OPPORTUNITY_REMOVE:
      return {
        ...state,
        opportunityDetail: {},
        opportunities: removeOpportunity(
          state.opportunities,
          action.opportunityId,
          'upcoming'
        )
      };
    default:
      return state;
  }
};

export default opportunitiesReducer;
