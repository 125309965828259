import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IonToolbar, IonFooter } from '@ionic/react';
import { shieldCheckmarkOutline, calendarOutline } from 'ionicons/icons';
import useViewport from 'hooks/useViewport';
import Icon from 'common/Icon';
import home from 'assets/images/iconImages/nanno-home.svg';
import clock from 'assets/images/iconImages/clock.svg';

const BottomNav = () => {
  const history = useHistory();
  const location = useLocation();
  const { width } = useViewport();
  const { pathname } = location;

  if (width > 640) return null;

  return (
    <IonFooter className="bottom-nav-container">
      <IonToolbar color="$primary">
        <div className="nav-buttons-container">
          <button
            className={
              pathname.indexOf('/provider/dashboard') > -1 ? 'selected' : ''
            }
            type="button"
            onClick={() => history.push('/provider/dashboard')}
          >
            <Icon icon={home} size="large" color="" />
            <h2>Dashboard</h2>
          </button>
          <button
            className={
              pathname.indexOf('/provider/my-schedule') > -1 ? 'selected' : ''
            }
            type="button"
            onClick={() => history.push('/provider/my-schedule')}
          >
            <Icon icon={calendarOutline} size="large" color="" />
            <h2>Schedule</h2>
          </button>
          <button
            className={
              pathname.indexOf('/provider/bookings') > -1 ? 'selected' : ''
            }
            type="button"
            onClick={() => history.push('/provider/bookings')}
          >
            <ion-icon icon={clock} size="large" color="" />
            <h2>Bookings</h2>{' '}
          </button>
          <button
            className={
              pathname.indexOf('/provider/opportunities') > -1 ? 'selected' : ''
            }
            type="button"
            onClick={() => history.push('/provider/opportunities')}
          >
            <Icon icon={shieldCheckmarkOutline} size="large" color="" />
            <h2>Opportunities</h2>
          </button>
          <button type="button" className="empty">
            <h2>&nbsp;</h2>
          </button>
        </div>
      </IonToolbar>
    </IonFooter>
  );
};

export default BottomNav;
