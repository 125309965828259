import actionTypes from 'constants/actionTypes';

const initialState = {
  user: {},
  cprCard: {},
  errors: {},
  loading: false,
  infoSuccessfullySent: false,
  verificationNeeded: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROVIDER_USER_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.PROVIDER_IDENTITY_VERIFICATION_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: action.errors
      };
    case actionTypes.PROVIDER_IDENTITY_VERIFICATION_NEEDED:
      return {
        ...state,
        loading: false,
        verificationNeeded: action.verificationNeeded,
        errors: action.errors
      };
    case actionTypes.PROVIDER_ONBOARDING_CPR_UPLOAD:
      return {
        ...state,
        loading: false,
        cprCard: { ...state.cprCard, ...action.cprCard }
      };
    case actionTypes.PROVIDER_ONBOARDING_USER_UPDATE:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.user }
      };
    case actionTypes.PROVIDER_USER_INFO_SENT:
      return {
        ...state,
        loading: false,
        infoSuccessfullySent: action.infoSent
      };
    case actionTypes.PROVIDER_INDEPENDENT_CONTRACTOR_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: action.errors
      };
    case actionTypes.PROVIDER_INDEPENDENT_CONTRACTOR_SIGNED:
      return {
        ...state,
        loading: false,
        independentContractorSigned: action.independentContractorSigned,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default userReducer;
