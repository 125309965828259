import actionTypes from 'constants/actionTypes';

const initialState = {
  notifications: [],
  showNotifications: true,
  loading: false,
  urgentActionDeleted: false,
  errors: {}
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATIONS_LOADING:
      return {
        ...state,
        loading: action.loading,
        showNotifications: action.loading ? true : state.showNotifications
      };
    case actionTypes.NOTIFICATIONS_LOAD:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.concat(action.notifications)
      };
    case actionTypes.NOTIFICATION_CHECKED:
      return {
        ...state,
        loading: false
      };
    case actionTypes.NOTIFICATIONS_ADD:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.concat([action.notification])
      };
    case actionTypes.NOTIFICATIONS_REMOVE:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.notificationId
        )
      };
    case actionTypes.NOTIFICATIONS_ERRORS:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };
    case actionTypes.NOTIFICATIONS_SHOW:
      return {
        ...state,
        showNotifications: action.show
      };
    case actionTypes.NOTIFICATION_BOOKER_CONTACTED:
      return {
        ...state,
        loading: false
      };
    case actionTypes.NOTIFICATION_DELETED:
      return {
        ...state,
        urgentActionDeleted: true
      };
    default:
      return state;
  }
};

export default notificationReducer;
