import React from 'react';
import PropTypes from 'prop-types';
import { IonSpinner } from '@ionic/react';

const Spinner = ({ name = 'crescent', color }) => {
  return <IonSpinner name={name} color={color} />;
};

Spinner.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string
};

export default Spinner;
