const actionTypes = {
  ERASE_STORE_DATA: 'ERASE_STORE_DATA',

  /* Common actions */

  // Notifications
  NOTIFICATIONS_LOAD: 'NOTIFICATIONS_LOAD',
  NOTIFICATIONS_ADD: 'NOTIFICATIONS_ADD',
  NOTIFICATIONS_REMOVE: 'NOTIFICATIONS_REMOVE',
  NOTIFICATIONS_LOADING: 'NOTIFICATIONS_LOADING',
  NOTIFICATIONS_ERRORS: 'NOTIFICATIONS_ERRORS',
  NOTIFICATIONS_SHOW: 'NOTIFICATIONS_SHOW',
  NOTIFICATION_CHECKED: 'NOTIFICATION_CHECKED',
  NOTIFICATION_FINALIZED: 'NOTIFICATION_FINALIZED',
  NOTIFICATION_BOOKER_CONTACTED: 'NOTIFICATION_BOOKER_CONTACTED',
  NOTIFICATION_DELETED: 'NOTIFICATION_DELETED',

  /* Booker actions */

  // Bookings
  BOOKINGS_UPDATE_SINGLE_BOOKING_LOADED_FLAG:
    'BOOKER_UPDATE_SINGLE_BOOKING_LOADED_FLAGS_LOADING',
  BOOKINGS_LOADING: 'BOOKER_BOOKINGS_LOADING',
  PAST_BOOKINGS_LOADED: 'BOOKER_PAST_BOOKINGS_LOADED',
  UPCOMING_BOOKINGS_LOADED: 'BOOKER_UPCOMING_BOOKINGS_LOADED',
  BOOKINGS_SINGLE_BOOKING_LOADED: 'BOOKER_BOOKINGS_SINGLE_BOOKING_LOADED',
  BOOKINGS_SUMMARY_LOADED: 'BOOKER_BOOKINGS_SUMMARY_LOADED',
  REQUESTED_BOOKINGS_LOADED: 'BOOKER_REQUESTED_BOOKINGS_LOADED',
  BOOKINGS_ERRORS: 'BOOKER_BOOKINGS_ERRORS',
  BOOKINGS_MOVE: 'BOOKER_BOOKINGS_MOVE',
  BOOKINGS_UPDATE: 'BOOKER_BOOKINGS_UPDATE',
  BOOKING_START: 'BOOKER_BOOKING_START',
  BOOKING_END: 'BOOKER_BOOKING_END',
  BOOKING_RESET: 'BOOKER_BOOKING_RESET',
  BOOKING_SENT: 'BOOKER_BOOKING_SENT',
  BOOKING_ERRORS: 'BOOKER_BOOKING_ERRORS',
  BOOKING_RESET_ERRORS: 'BOOKER_BOOKING_RESET_ERRORS',
  BOOKING_UPDATE: 'BOOKER_BOOKING_UPDATE',
  BOOKING_UPDATE_ATTRIBUTE: 'BOOKER_BOOKING_UPDATE_ATTRIBUTE',
  BOOKING_CANCEL: 'BOOKER_BOOKING_CANCEL',
  BOOKING_RESET_CANCEL: 'BOOKER_BOOKING_RESET_CANCEL',
  BOOKING_CANCEL_ERRORS: 'BOOKER_BOOKING_CANCEL_ERRORS',
  BOOKING_LOADING: 'BOOKER_BOOKING_LOADING',
  BOOKING_COUPON_LOADING: 'BOOKER_BOOKING_COUPON_LOADING',
  BOOKING_COUPON_ERROR: 'BOOKER_BOOKING_COUPON_ERROR',
  BOOKING_COUPON: 'BOOKER_BOOKING_COUPON',
  BOOKING_GIFT_CERTIFICATE: 'BOOKER_BOOKING_GIFT_CERTIFICATE',
  BOOKING_CHANGE_STEP: 'BOOKER_BOOKING_CHANGE_STEP',
  BOOKING_NO_SHOW_FLAG: 'BOOKING_NO_SHOW_FLAG',

  // User
  USER_LOGIN: 'BOOKER_USER_LOGIN',
  USER_LOADING: 'BOOKER_USER_LOADING',
  USER_LOGOUT: 'BOOKER_USER_LOGOUT',
  USER_CHECK_TERMS: 'BOOKER_USER_CHECK_TERMS',
  USER_CHECK_IDV: 'BOOKER_USER_CHECK_IDV',
  USER_IDV_LOADED: 'BOOKER_USER_IDV_LOADED',
  USER_IDV_ANSWERED: 'BOOKER_USER_IDV_ANSWERED',
  USER_IDV_ERRORS: 'BOOKER_USER_IDV_ERRORS',
  USER_GET_VERIFYUI_TOKEN: 'BOOKER_USER_GET_VERIFYUI_TOKEN',
  USER_PASSWORD_CHANGE: 'BOOKER_USER_PASSWORD_CHANGE',
  USER_LOADED: 'BOOKER_USER_LOADED',
  USER_SET_FLAG_UPDATED: 'BOOKER_USER_SET_FLAG_UPDATED',
  USER_UPDATE: 'BOOKER_USER_UPDATE',
  USER_UPDATE_LOCALLY: 'BOOKER_USER_UPDATE_LOCALLY',
  USER_TERMS_OF_SERVICE_ERRORS: 'BOOKER_USER_TERMS_OF_SERVICE_ERRORS',
  USER_SET_HAS_CALLED_IDV: 'BOOKER_USER_SET_HAS_CALLED_IDV',
  USER_ERRORS: 'BOOKER_USER_ERRORS',
  USER_RESET_ERRORS: 'BOOKER_USER_RESET_ERRORS',
  USER_RESET_PASSWORD_CHANGED: 'USER_RESET_PASSWORD_CHANGED',
  USER_PHONE_NUMBER_CONFIRM: 'USER_PHONE_NUMBER_CONFIRM',
  USER_PHONE_NUMBER_CONFIRM_RESET: 'USER_PHONE_NUMBER_CONFIRM_RESET',
  USER_PHONE_NUMBER_UPDATED: 'USER_PHONE_NUMBER_UPDATED',
  USER_PHONE_NUMBER_UPDATED_RESET: 'USER_PHONE_NUMBER_UPDATED_RESET',
  USER_AVAILABILITIES: 'USER_AVAILABILITIES',
  USER_AVAILABILITIES_SAVED: 'USER_AVAILABILITIES_SAVED',
  USER_AVAILABILITIES_RESET: 'USER_AVAILABILITIES_RESET',
  USER_CREATE_AUTHENTICATING_USER: 'PROVIDER_CREATE_AUTHENTICATING_USER',
  USER_CONSENT_AUTHENTICATING_USER: 'PROVIDER_CONSENT_AUTHENTICATING_USER',
  USER_GET_AUTHENTICATING_QUIZ: 'PROVIDER_GET_AUTHENTICATING_QUIZ',
  USER_SUBMIT_AUTHENTICATING_QUIZ: 'PROVIDER_SUBMIT_AUTHENTICATING_QUIZ',
  USER_HEAR_ABOUT_US: 'USER_HEAR_ABOUT_US',
  USER_SSN_SENT: 'BOOKER_USER_SSN_SENT',

  // User addresses
  ADDRESS_LOADED: 'BOOKER_ADDRESS_LOADED',
  ADDRESS_CREATE: 'BOOKER_ADDRESS_CREATE',
  ADDRESS_UPDATE: 'BOOKER_ADDRESS_UPDATE',
  ADDRESS_DELETE: 'BOOKER_ADDRESS_DELETE',
  ADDRESS_LOADING: 'BOOKER_ADDRESS_LOADING',
  ADDRESS_ERRORS: 'BOOKER_ADDRESS_ERRORS',
  ADDRESS_RESET: 'BOOKER_ADDRESS_RESET',

  // User credit cards
  CREDIT_CARD_LOADED: 'BOOKER_CREDIT_CARD_LOADED',
  CREDIT_CARD_CREATE: 'BOOKER_CREDIT_CARD_CREATE',
  CREDIT_CARD_UPDATE: 'BOOKER_CREDIT_CARD_UPDATE',
  CREDIT_CARD_DELETE: 'BOOKER_CREDIT_CARD_DELETE',
  CREDIT_CARD_LOADING: 'BOOKER_CREDIT_CARD_LOADING',
  CREDIT_CARD_ERRORS: 'BOOKER_CREDIT_CARD_ERRORS',
  CREDIT_CARD_STRIPE_CLIENT_SECRET: 'BOOKER_CREDIT_CARD_STRIPE_CLIENT_SECRET',
  CREDIT_CARD_RESET: 'BOOKER_CREDIT_CARD_RESET',

  // Family settings
  FAMILY_LOADED: 'BOOKER_FAMILY_LOADED',
  FAMILY_UPDATE: 'BOOKER_FAMILY_UPDATE',
  FAMILY_LOADING: 'BOOKER_FAMILY_LOADING',
  FAMILY_ERRORS: 'BOOKER_FAMILY_ERRORS',

  // booker shortlists
  SHORTLIST_LOADED: 'BOOKER_SHORTLIST_LOADED',
  SHORTLIST_PROVIDER_ADDED: 'BOOKER_SHORTLIST_PROVIDER_ADDED',
  SHORTLIST_PROVIDER_REMOVED: 'BOOKER_SHORTLIST_PROVIDER_REMOVED',
  SHORTLIST_MODAL_OPEN: 'BOOKER_SHORTLIST_MODAL_OPEN',
  SHORTLIST_CREATE: 'BOOKER_SHORTLIST_CREATE',
  SHORTLIST_CLONE: 'BOOKER_SHORTLIST_CLONE',
  SHORTLIST_ALL_UPDATE: 'BOOKER_SHORTLIST_ALL_UPDATE',
  SHORTLIST_UPDATE: 'BOOKER_SHORTLIST_UPDATE',
  SHORTLIST_DELETE: 'BOOKER_SHORTLIST_DELETE',
  SHORTLIST_LOADING: 'BOOKER_SHORTLIST_LOADING',
  SHORTLIST_ERRORS: 'BOOKER_SHORTLIST_ERRORS',

  // Providers
  PROVIDER_RESET: 'PARENT_PROVIDER_RESET',
  PROVIDER_RESET_ERRORS: 'PARENT_PROVIDER_RESET_ERRORS',
  PROVIDER_LOADED: 'PARENT_PROVIDER_LOADED',
  PROVIDER_LOADED_FROM_STORAGE: 'PARENT_PROVIDER_LOADED_FROM_STORAGE',
  PROVIDER_LOADING: 'PARENT_PROVIDER_LOADING',
  PROVIDER_UPDATE: 'PARENT_PROVIDER_UPDATE',
  PROVIDER_ERRORS: 'PARENT_PROVIDER_ERRORS',
  PROVIDER_FILTERS: 'PARENT_PROVIDER_FILTERS',
  PROVIDER_REVIEWS: 'PARENT_PROVIDER_REVIEWS',

  // Subscription
  SUBSCRIPTION_LOADING: 'SUBSCRIPTION_LOADING',
  SUBSCRIPTION_PLANS_LOADED: 'SUBSCRIPTION_PLANS_LOADED',
  SUBSCRIPTION_LOADED: 'SUBSCRIPTION_LOADED',
  SUBSCRIPTION_ERRORS: 'SUBSCRIPTION_ERRORS',
  SUBSCRIPTION_UPDATE: 'SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_RESET_SUCCESSFULLY_UPDATED:
    'SUBSCRIPTION_RESET_SUCCESSFULLY_UPDATED',
  SUBSCRIPTION_INQUIRY_CREATE: 'SUBSCRIPTION_INQUIRY_CREATE',
  SUBSCRIPTION_INQUIRY_MODAL_LOADING: 'SUBSCRIPTION_INQUIRY_MODAL_LOADING',

  // Reviews
  REVIEW_UPDATE: 'BOOKER_REVIEW_UPDATE',
  REVIEW_ERRORS: 'BOOKER_REVIEW_ERRORS',
  REVIEW_LOADING: 'BOOKER_REVIEW_LOADING',
  REVIEW_SENT: 'BOOKER_REVIEW_SENT',
  REVIEW_RESET: 'BOOKER_REVIEW_RESET',
  REVIEW_RESET_SUCCESSFULLY_REVIEWED:
    'BOOKER_REVIEW_RESET_SUCCESSFULLY_REVIEWED',

  // Navigation
  NAVIGATION_SET_PROPS: 'NAVIGATION_SET_PROPS',
  NAVIGATION_DELETE_PROPS: 'NAVIGATION_DELETE_PROPS',

  /* Provider actions */

  // User
  PROVIDER_USER_LOADING: 'PROVIDER_USER_LOADING',
  PROVIDER_USER_INFO_SENT: 'PROVIDER_USER_INFO_SENT',
  PROVIDER_IDENTITY_VERIFICATION_LOADING:
    'PROVIDER_IDENTITY_VERIFICATION_LOADING',
  PROVIDER_IDENTITY_VERIFICATION_NEEDED:
    'PROVIDER_IDENTITY_VERIFICATION_NEEDED',
  PROVIDER_INDEPENDENT_CONTRACTOR_LOADING:
    'PROVIDER_INDEPENDENT_CONTRACTOR_LOADING',
  PROVIDER_INDEPENDENT_CONTRACTOR_SIGNED:
    'PROVIDER_INDEPENDENT_CONTRACTOR_SIGNED',

  // Bookings
  PROVIDER_BOOKINGS_LOADING: 'PROVIDER_BOOKINGS_LOADING',
  PROVIDER_BOOKINGS_LOADED: 'PROVIDER_BOOKINGS_LOADED',
  PROVIDER_UPCOMING_BOOKINGS_LOADED: 'PROVIDER_UPCOMING_BOOKINGS_LOADED',
  PROVIDER_PAST_BOOKINGS_LOADED: 'PROVIDER_PAST_BOOKINGS_LOADED',
  PROVIDER_BOOKING_LOADED: 'PROVIDER_BOOKING_LOADED',
  PROVIDER_BOOKINGS_ERRORS: 'PROVIDER_BOOKINGS_ERRORS',
  PROVIDER_BOOKINGS_SUMMARY_LOADING: 'PROVIDER_BOOKINGS_SUMMARY_LOADING',
  PROVIDER_BOOKINGS_SUMMARY_LOADED: 'PROVIDER_BOOKINGS_SUMMARY_LOADED',
  PROVIDER_BOOKINGS_RESET_SINGLE_BOOKING_LOADED:
    'PROVIDER_BOOKINGS_RESET_SINGLE_BOOKING_LOADED',
  PROVIDER_BOOKING_RECONCILE_PENDING: 'PROVIDER_BOOKING_RECONCILE_PENDING',
  PROVIDER_BOOKINGS_UPDATE: 'PROVIDER_BOOKINGS_UPDATE',
  PROVIDER_BOOKING_CANCEL: 'PROVIDER_BOOKING_CANCEL',
  PROVIDER_BOOKING_RESET_CANCEL: 'PROVIDER_BOOKING_RESET_CANCEL',
  PROVIDER_BOOKING_RECONCILED: 'PROVIDER_BOOKING_RECONCILED',

  // Opportunities
  PROVIDER_OPPORTUNITIES_ERRORS: 'PROVIDER_OPPORTUNITIES_ERRORS',
  PROVIDER_OPPORTUNITIES_SUMMARY_LOADED:
    'PROVIDER_OPPORTUNITIES_SUMMARY_LOADED',
  PROVIDER_OPPORTUNITIES_LOADED: 'PROVIDER_OPPORTUNITIES_LOADED',
  PROVIDER_PAST_OPPORTUNITIES_LOADED: 'PROVIDER_PAST_OPPORTUNITIES_LOADED',
  PROVIDER_OPPORTUNITIES_LOADING: 'PROVIDER_OPPORTUNITIES_LOADING',
  PROVIDER_OPPORTUNITY_LOADED: 'PROVIDER_OPPORTUNITY_LOADED',
  PROVIDER_OPPORTUNITY_REMOVE: 'PROVIDER_OPPORTUNITY_REMOVE',
  PROVIDER_OPPORTUNITY_ASSIGNMENT_LOADED:
    'PROVIDER_OPPORTUNITY_ASSIGNMENT_LOADED',
  PROVIDER_OPPORTUNITY_LOADED_RESET_FLAG:
    'PROVIDER_OPPORTUNITY_LOADED_RESET_FLAG',
  PROVIDER_OPPORTUNITY_ADDED: 'PROVIDER_OPPORTUNITY_ADDED',
  PROVIDER_OPPORTUNITY_UPDATE: 'PROVIDER_OPPORTUNITY_UPDATE',

  // Addresses
  PROVIDER_ADDRESS_LOADED: 'PROVIDER_ADDRESS_LOADED',
  PROVIDER_ADDRESS_CREATE: 'PROVIDER_ADDRESS_CREATE',
  PROVIDER_ADDRESS_UPDATE: 'PROVIDER_ADDRESS_UPDATE',
  PROVIDER_ADDRESS_DELETE: 'PROVIDER_ADDRESS_DELETE',
  PROVIDER_ADDRESS_LOADING: 'PROVIDER_ADDRESS_LOADING',
  PROVIDER_ADDRESS_ERRORS: 'PROVIDER_ADDRESS_ERRORS',
  PROVIDER_ADDRESS_RESET: 'PROVIDER_ADDRESS_RESET',

  // Onboarding
  PROVIDER_ONBOARDING_CPR_UPLOAD: 'PROVIDER_ONBOARDING_CPR_UPLOAD',
  PROVIDER_ONBOARDING_SERVICE_TYPES_LOAD:
    'PROVIDER_ONBOARDING_SERVICE_TYPES_LOAD',
  PROVIDER_ONBOARDING_SUPER_POWERS_LOAD:
    'PROVIDER_ONBOARDING_SUPER_POWERS_LOAD',
  PROVIDER_ONBOARDING_SUPER_POWERS_UPDATE:
    'PROVIDER_ONBOARDING_SUPER_POWERS_UPDATE',
  PROVIDER_ONBOARDING_SERVICE_TYPES_UPDATE:
    'PROVIDER_ONBOARDING_SERVICE_TYPES_UPDATE',
  PROVIDER_ONBOARDING_SUPER_POWERS_RESET:
    'PROVIDER_ONBOARDING_SUPER_POWERS_RESET',
  PROVIDER_ONBOARDING_MORE_ABOUT_YOU_LOAD:
    'PROVIDER_ONBOARDING_MORE_ABOUT_YOU_LOAD',
  PROVIDER_ONBOARDING_MORE_ABOUT_YOU_UPDATE:
    'PROVIDER_ONBOARDING_MORE_ABOUT_YOU_UPDATE',
  PROVIDER_ONBOARDING_MORE_ABOUT_YOU_SAVE:
    'PROVIDER_ONBOARDING_MORE_ABOUT_YOU_SAVE',
  PROVIDER_ONBOARDING_MORE_ABOUT_YOU_RESET:
    'PROVIDER_ONBOARDING_MORE_ABOUT_YOU_RESET',
  PROVIDER_ONBOARDING_PROFILE_UPDATE: 'PROVIDER_ONBOARDING_PROFILE_UPDATE',
  PROVIDER_ONBOARDING_PROFILE_SAVE: 'PROVIDER_ONBOARDING_PROFILE_SAVE',
  PROVIDER_ONBOARDING_PROFILE_RESET: 'PROVIDER_ONBOARDING_PROFILE_RESET',
  PROVIDER_ONBOARDING_PROFILE_LOAD: 'PROVIDER_ONBOARDING_PROFILE_LOAD',
  PROVIDER_ONBOARDING_REFERENCES_UPDATE:
    'PROVIDER_ONBOARDING_REFERENCES_UPDATE',
  PROVIDER_ONBOARDING_REFERENCES_SAVE: 'PROVIDER_ONBOARDING_REFERENCES_SAVE',
  PROVIDER_ONBOARDING_REFERENCES_RESET: 'PROVIDER_ONBOARDING_REFERENCES_RESET',
  PROVIDER_ONBOARDING_REFERENCES_LOAD: 'PROVIDER_ONBOARDING_REFERENCES_LOAD',
  PROVIDER_ONBOARDING_PERSONALITY_UPDATE:
    'PROVIDER_ONBOARDING_PERSONALITY_UPDATE',
  PROVIDER_ONBOARDING_PERSONALITY_SAVE: 'PROVIDER_ONBOARDING_PERSONALITY_SAVE',
  PROVIDER_ONBOARDING_PERSONALITY_RESET:
    'PROVIDER_ONBOARDING_PERSONALITY_RESET',
  PROVIDER_ONBOARDING_PERSONALITY_TAKEN:
    'PROVIDER_ONBOARDING_PERSONALITY_TAKEN',
  PROVIDER_ONBOARDING_SKILLS_SENT: 'PROVIDER_ONBOARDING_SKILLS_SENT',
  PROVIDER_ONBOARDING_SET_TYPEFORM_STATUS:
    'PROVIDER_ONBOARDING_SET_TYPEFORM_STATUS',
  PROVIDER_ONBOARDING_IDV_LOADED: 'PROVIDER_ONBOARDING_IDV_LOADED',
  PROVIDER_ONBOARDING_IDV_ANSWERED: 'PROVIDER_ONBOARDING_IDV_ANSWERED',
  PROVIDER_ONBOARDING_LOADING: 'PROVIDER_ONBOARDING_LOADING',
  PROVIDER_ONBOARDING_ERRORS: 'PROVIDER_ONBOARDING_ERRORS',
  PROVIDER_ONBOARDING_RESET_ERRORS: 'PROVIDER_ONBOARDING_RESET_ERRORS',
  PROVIDER_ONBOARDING_GET_VERIFYUI_TOKEN:
    'PROVIDER_ONBOARDING_GET_VERIFYUI_TOKEN',
  PROVIDER_ONBOARDING_RESET_IDV_DATA: 'PROVIDER_ONBOARDING_RESET_IDV_DATA',
  PROVIDER_ONBOARDING_USER_UPDATE: 'PROVIDER_ONBOARDING_USER_UPDATE',

  // Toast messages
  TOAST_ADD: 'TOAST_ADD',
  TOAST_REMOVE: 'TOAST_REMOVE',

  // Services
  SERVICE_LOADING: 'SERVICE_LOADING',
  SERVICE_LOADED: 'SERVICE_LOADED',
  SERVICE_ERROR: 'SERVICE_ERROR',

  // Skills Tests
  SKILLS_TESTS_SET_TESTS: 'SKILLS_TESTS_SET_TESTS',
  SKILLS_TESTS_SET_CURRENT_QUESTION: 'SKILLS_TESTS_SET_CURRENT_QUESTION'
};

export default actionTypes;
