import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'dayjs';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Capacitor, Plugins } from '@capacitor/core';
import Honeybadger from 'honeybadger-js';
import { Helmet } from 'react-helmet';
import '@ionic/react/css/core.css';
import './assets/stylesheets/App.scss';
import { ViewportContext } from './context';
import store from './store/store';
import {
  loadUserFromStorage,
  sendPushNotificationSubscription
} from './booker/actions/userActions';
import { updateBooking } from './booker/actions/bookingActions';
import { loadProvidersFromStorage } from './booker/actions/providerActions';
import { addToast, removeToast } from './common/actions/toastActions';
import channelIDs from './constants/channel-ids';
import {
  initializeFirebase,
  registerServiceWorker,
  registerMessaging
} from './utils/pushNotification';
import { innerTrendsUserRegister } from './utils/innerTrends';
import { initializeChannels, subscribeToChannel } from './utils/subscriptions';
import channelEventsListener from './utils/events';
import addJiraWidgetObserver from './utils/jiraWidgetObserver';
import AppUrlListener from './utils/AppUrlListener';
import AppUpdateChecker from './utils/AppUpdateChecker';
import Toast from './common/Toast';
import Notifications from './common/Notifications';
import Routes from './routes/Routes';
import ErrorBoundary from './ErrorBoundary';
import FallbackPage from './FallbackPage';

const { SplashScreen } = Plugins;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.swRegistration = null;
    this.honeybadger = null;

    const user = JSON.parse(window.localStorage.getItem('user'));
    if (user && user.token) {
      const { loadUser } = this.props;
      loadUser(user);
    }

    this.loadPreviouslyStartedBooking();
    this.loadPreviouslyProviders();
  }

  componentDidMount() {
    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);

    const isOnIOS =
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload';
    window.addEventListener(eventName, this.saveToLocalStorage);

    if (Capacitor && Capacitor.platform !== 'ios') {
      registerServiceWorker();
    }

    if (process.env.NODE_ENV === 'production') {
      this.registerHoneyBadger();
    }

    this.checkEligibility();
    addJiraWidgetObserver();
  }

  componentDidUpdate(prevProps) {
    const { isLoggedIn, user } = this.props;
    const { isLoggedIn: wasLoggedIn } = prevProps;
    if (!wasLoggedIn && isLoggedIn) {
      // User alert to allow push notifications
      if (Capacitor && Capacitor.platform !== 'ios') {
        initializeFirebase();
        registerMessaging();
      }

      // Initialize websockets service
      if (user && user.uuid) {
        initializeChannels();
        const channel = subscribeToChannel(
          channelIDs(user.uuid).EVENTS_CHANNEL
        );
        channelEventsListener({ channel, user });

        if (user.role === 'provider') {
          const gtmChannel = subscribeToChannel(
            `user-${user.uuid}-ga-backend-events`
          );

          gtmChannel.bind(
            `user-${user.uuid}-ga-backend-events`,
            (eventName) => {
              dataLayer.push({
                event: eventName,
                userId: user.id
              });
            }
          );
        }

        // InnerTrends user identification
        if (user.role === 'booker') {
          innerTrendsUserRegister({
            id: user.id,
            email: user.email,
            createdAt: user.created_at
          });
        }
      }
    }

    this.checkEligibility();
  }

  componentWillUnmount() {
    this.saveToLocalStorage();
    const isOnIOS =
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPhone/i);
    const eventName = isOnIOS ? 'pagehide' : 'beforeunload';
    window.removeEventListener(eventName, this.saveToLocalStorage);
  }

  registerHoneyBadger = () => {
    const config = {
      api_key: process.env.REACT_APP_HONEYBADGER_API_KEY,
      environment: process.env.REACT_APP_ENV || process.env.NODE_ENV,
      revision: 'git SHA/project version'
    };
    this.honeybadger = Honeybadger.configure(config);
  };

  eraseUserData = (signedOut) => {
    const { userLoading, resetReduxData, user } = this.props;
    if (!userLoading && signedOut) {
      if (user.role === 'booker') {
        window.location = '/sign-in';
      } else {
        window.location = '/provider/sign-in';
      }
      resetReduxData();
    }
  };

  saveToLocalStorage = () => {
    const { isLoggedIn } = this.props;
    if (isLoggedIn) {
      this.saveBooking();
      this.saveProviders();
    }
  };

  saveBooking = () => {
    const booking = JSON.stringify(store.getState().bookingReducer.booking);
    window.localStorage.setItem('callemmy-booking', booking);
  };

  loadPreviouslyStartedBooking = () => {
    const { updateBookingInfo } = this.props;
    let storedBooking = {};
    try {
      storedBooking = JSON.parse(
        window.localStorage.getItem('callemmy-booking')
      );
    } catch {
      window.localStorage.setItem('callemmy-booking', {});
    }

    // If draft booking has started with more than 24h discard it
    if (
      storedBooking &&
      storedBooking.startedAt &&
      moment().diff(moment(storedBooking.startedAt), 'hours') < 24
    ) {
      updateBookingInfo({ ...storedBooking });
    }
  };

  saveProviders = () => {
    const providers =
      JSON.stringify(store.getState().providerReducer.providers) || [];
    window.localStorage.setItem('callemmy-providers', providers);
  };

  loadPreviouslyProviders = () => {
    const { loadProviders } = this.props;
    const storedProviders = JSON.parse(
      window.localStorage.getItem('callemmy-providers')
    );
    if (storedProviders) {
      loadProviders({ ...storedProviders });
    }
  };

  checkEligibility = () => {
    const { user = {} } = this.props;
    if (
      user.eligibility_status === 'rejected' &&
      user.role === 'provider' &&
      window.location.href.indexOf('/provider/ineligible') === -1
    ) {
      window.location = '/provider/ineligible';
    }
  };

  render() {
    const {
      toasts,
      closeToast,
      hasAcceptedTerms,
      isLoggedIn,
      user,
      toggleToast
    } = this.props;

    return (
      <IonApp className="App">
        <Helmet>
          <title>Call Emmy</title>
          <meta
            name="description"
            content="Connecting families with great providers, on demand"
          />
        </Helmet>
        <AppUpdateChecker>
          {toasts.map((toast) => {
            const {
              id,
              isOpen,
              color,
              duration,
              position,
              message,
              animated,
              buttons,
              cssClass
            } = toast;
            return (
              <Toast
                key={id}
                isOpen={isOpen}
                color={color}
                duration={duration}
                position={position}
                message={message}
                animated={animated}
                buttons={buttons}
                cssClass={cssClass}
                close={() => closeToast(id)}
              />
            );
          })}
          <IonReactRouter>
            {hasAcceptedTerms && <Notifications />}
            <AppUrlListener />
            <ErrorBoundary
              honeybadger={this.honeybadger}
              isLoggedIn={isLoggedIn}
              user={user}
            >
              <Suspense fallback={<FallbackPage />}>
                <Routes toggleToast={toggleToast} />
              </Suspense>
            </ErrorBoundary>
          </IonReactRouter>
        </AppUpdateChecker>
      </IonApp>
    );
  }
}

App.contextType = ViewportContext;

const mapStateToProps = (state) => ({
  isLoggedIn: state.userReducer.isLoggedIn,
  hasAcceptedTerms: state.userReducer.hasAcceptedTerms,
  user: state.userReducer.user,
  userLoading: state.userReducer.loading,
  toasts: state.toastReducer.toasts
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sendPushNotificationSubscription,
      loadUser: loadUserFromStorage,
      updateBookingInfo: updateBooking,
      loadProviders: loadProvidersFromStorage,
      closeToast: removeToast,
      toggleToast: addToast
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
