import React from 'react';
import { IonButton } from '@ionic/react';
import Spinner from './Spinner';

const Button = ({
  color,
  dataTrack,
  onClick,
  onBlur,
  fill,
  size,
  disabled,
  className = '',
  href,
  reference,
  children,
  target,
  id = '',
  loading,
  loadingName,
  expand
}) => {
  return (
    <IonButton
      data-track={dataTrack}
      id={id}
      ref={reference}
      target={target}
      className={`callemmy-button ${className}`}
      onClick={onClick}
      onBlur={onBlur}
      color={color}
      fill={fill}
      size={size}
      disabled={disabled}
      href={href}
      expand={expand}
    >
      {!loading && children}
      {loading && <Spinner name={loadingName} />}
    </IonButton>
  );
};

export default Button;
