import actionTypes from 'constants/actionTypes';

const initialState = {
  services: [],
  loading: false,
  apiCalled: false,
  errors: {}
};

const serviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SERVICE_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.SERVICE_LOADED:
      return {
        ...state,
        loading: false,
        services: action.services,
        apiCalled: true
      };
    case actionTypes.SHORTLIST_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors
      };
    default:
      return state;
  }
};

export default serviceReducer;
