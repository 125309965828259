import bookingReducer from './bookingReducer';
import userReducer from './userReducer';
import addressReducer from './addressReducer';
import familySettingsReducer from './familyReducer';
import creditCardReducer from './creditCardReducer';
import shortlistReducer from './shortlistReducer';
import providerReducer from './providerReducer';
import subscriptionReducer from './subscriptionReducer';
import reviewReducer from './reviewReducer';
import navigationReducer from './navigationReducer';
import serviceReducer from '../../common/reducers/serviceReducer';

const bookerRootReducer = {
  bookingReducer,
  userReducer,
  addressReducer,
  familySettingsReducer,
  creditCardReducer,
  shortlistReducer,
  providerReducer,
  subscriptionReducer,
  reviewReducer,
  navigationReducer,
  serviceReducer
};

export default bookerRootReducer;
