import React, { useEffect, useState } from 'react';
import { Plugins, Capacitor } from '@capacitor/core';
import UpdateAppPage from 'common/UpdateAppPage';

const { Device } = Plugins;

const AppUpdateChecker = ({ children }) => {
  const [needsUpdate, setNeedsUpdate] = useState(false);
  useEffect(() => {
    const getDeviceAppVersion = async () => {
      const info = await Device.getInfo();
      const { appVersion, platform } = info || {};
      if (platform === 'ios') {
        return Promise.resolve(appVersion);
      }
      return Promise.resolve(null);
    };

    const getAppNewVersion = async () => {
      const appInfo = await Device.getInfo();
      const { appId } = appInfo;
      const response = await fetch(
        `https://itunes.apple.com/lookup?bundleId=${appId}`
      );
      const appleStoreInfo = await response.json();
      const { results, resultCount } = appleStoreInfo;
      if (resultCount === 1) {
        const info = results.length > 0 ? results[0] : {};
        const { version } = info;
        return Promise.resolve(version);
      }
      return Promise.resolve(null);
    };

    const compareAppVersion = async () => {
      const currentAppVersion = await getDeviceAppVersion();
      const newAppVersion = await getAppNewVersion();
      if (
        currentAppVersion &&
        newAppVersion &&
        currentAppVersion !== newAppVersion
      ) {
        const currentAppVersionNumbers = currentAppVersion.split('.');
        const newAppVersionNumbers = newAppVersion.split('.');
        if (
          currentAppVersionNumbers.length > 1 &&
          newAppVersionNumbers.length > 1 &&
          (currentAppVersionNumbers[0] !== newAppVersionNumbers[0] ||
            currentAppVersionNumbers[1] !== newAppVersionNumbers[1])
        ) {
          setNeedsUpdate(true);
        }
      }
    };

    if (Capacitor && Capacitor.platform === 'ios') {
      compareAppVersion();
    }
  }, []);

  if (process.env.REACT_APP_CHECK_UPDATES === 'true' && needsUpdate) {
    return <UpdateAppPage />;
  }

  return children;
};

export default AppUpdateChecker;
