import actionTypes from 'constants/actionTypes';
import { getBrowser } from 'utils/browser';
import { disconnectChat } from 'utils/chat';
import { login, api } from '../../api/connect';

const currentUser = JSON.parse(window.localStorage.getItem('user'));

export function userLogin({
  email,
  password,
  id,
  tokenId,
  googleId,
  provider,
  role,
  ios,
  appleCode
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    login()
      .post('/sign_in', {
        user: {
          email,
          password,
          id,
          token_id: tokenId,
          auth_uid: googleId,
          auth_provider: provider,
          role_name: role,
          ios,
          apple_code: appleCode
        }
      })
      .then((response) => {
        const { data = {} } = response;
        dispatch({
          type: actionTypes.USER_LOGIN,
          user: { ...data }
        });

        if (data)
          dataLayer.push({
            event: `login_existing_${data.role}`,
            userId: data.id
          });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let signupErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            signupErrors = { message: data.error };
          } else {
            signupErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: signupErrors
        });
      });
  };
}

export function userSignup({
  email,
  password,
  userId,
  tokenId,
  provider,
  role,
  ios,
  appleCode,
  firstName,
  lastName
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    login()
      .post('/sign_up', {
        user: {
          email,
          password,
          role_name: role,
          token_id: tokenId,
          auth_uid: userId,
          auth_provider: provider,
          ios,
          apple_code: appleCode,
          first_name: firstName,
          last_name: lastName
        }
      })
      .then((response) => {
        const { data = {} } = response;
        dispatch({
          type: actionTypes.USER_LOGIN,
          user: { ...data }
        });

        if (data) {
          const createdAt = new Date(data.created_at);
          // Using the sign_up with an existing user will sign_in. With this we can
          // determine if the user was created with this request.
          const eventName =
            createdAt.setMinutes(createdAt.getMinutes() + 5) > new Date()
              ? 'sign_up'
              : 'login_existing_';

          dataLayer.push({
            event: `${eventName}_${data.role}`,
            userId: data.id
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let signupErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            signupErrors = { message: data.error };
          } else {
            signupErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: signupErrors
        });
      });
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .get('/sign_out', {})
      .then(() => {
        disconnectChat();
        dispatch({
          type: actionTypes.USER_LOGOUT
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.USER_LOGOUT
        });
      });
  };
}

export function resetUserData() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ERASE_STORE_DATA
    });
    window.localStorage.setItem('user', null);
    window.localStorage.setItem('callemmy-temporary-postal-code', null);
    window.localStorage.setItem('callemmy_invitation_token', null);
    window.localStorage.setItem('callemmy-booking', null);
    window.localStorage.setItem('callemmy-providers', null);
  };
}

export function loadUserFromStorage(user) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOGIN,
      user
    });
  };
}

export function checkTerms() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .get(`/tos?timestamp=${new Date().getTime()}`)
      .then((response) => {
        const { user } = response.data;
        dispatch({
          type: actionTypes.USER_CHECK_TERMS,
          hasAcceptedTerms: user.terms_of_service
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let termsErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            termsErrors = { message: data.error };
          } else {
            termsErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_TERMS_OF_SERVICE_ERRORS,
          errors: termsErrors
        });
      });
  };
}

export function userAcceptTerms() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .post('/accept_tos')
      .then(() => {
        dispatch({
          type: actionTypes.USER_CHECK_TERMS,
          hasAcceptedTerms: true
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let termsErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            termsErrors = { message: data.error };
          } else {
            termsErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: termsErrors
        });
      });
  };
}

export function addCreditCard({ id, paymentMethod }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .post('/bookers/credit_cards', {
        payment_method: paymentMethod,
        setup_intent_id: id
      })
      .then((response) => {
        const { data = {} } = response;
        dispatch({
          type: actionTypes.USER_CREDIT_CARD_CREATE,
          creditCard: data.credit_card
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let creditCardErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            creditCardErrors = { message: data.error };
          } else {
            creditCardErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: { ...creditCardErrors }
        });
      });
  };
}

export function getStripeClientSecret() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .get('/bookers/credit_cards/setup_intent_key')
      .then((response) => {
        const { data = {} } = response;
        const { key: clientSecret } = data;
        dispatch({
          clientSecret,
          type: actionTypes.USER_STRIPE_CLIENT_SECRET
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let creditCardErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            creditCardErrors = { message: data.error };
          } else {
            creditCardErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: { ...creditCardErrors }
        });
      });
  };
}

export function setLoading(loading) {
  return (dispatch) => {
    dispatch({
      loading,
      type: actionTypes.USER_LOADING
    });
  };
}

export function resetPasswordChangedFlag() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_RESET_PASSWORD_CHANGED
    });
  };
}

export function changePassword({ newPassword }) {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.USER_LOADING
    });

    api()
      .patch('/update_password', {
        user: {
          password: newPassword
        }
      })
      .then(() => {
        dispatch({
          type: actionTypes.USER_PASSWORD_CHANGE
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let passwordErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            passwordErrors = { message: data.error };
          } else {
            passwordErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: { ...passwordErrors }
        });
      });
  };
}

export function hasFilledIDV() {
  return (dispatch) => {
    api()
      .get('/bookers/identity_verifications/identity_check_fields_needed')
      .then((response) => {
        const { user = {} } = response.data;
        const { identity_check_fields_needed: checkNeeded } = user;
        dispatch({
          checkNeeded,
          type: actionTypes.USER_CHECK_IDV
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let checkingErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            checkingErrors = { message: data.error };
          } else {
            checkingErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: checkingErrors
        });
      });
  };
}

export function getIDV(user) {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.USER_LOADING
    });

    api()
      .post('/bookers/identity_verifications', { user })
      .then((response) => {
        const { identity_questions: idv } = response.data;
        dispatch({
          idv,
          type: actionTypes.USER_IDV_LOADED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let checkingErrors = { message: 'Something went wrong' };
        if (
          (data.authenticating_error && response.status === 422) ||
          response.status === 500
        ) {
          dispatch({
            type: actionTypes.USER_IDV_ERRORS,
            idv_attempts: data.attempts,
            errors: { idvError: data.authenticating_error || 'Error' }
          });
        } else {
          if (typeof data.error === 'string') {
            checkingErrors = { message: data.error };
          } else {
            checkingErrors = { ...data.error };
          }
          dispatch({
            type: actionTypes.USER_ERRORS,
            errors: checkingErrors
          });
        }
      });
  };
}

export function sendIDVAnswers(answers) {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.USER_LOADING
    });
    api()
      .post('/bookers/identity_verifications/answers', { answers })
      .then(() => {
        dispatch({
          type: actionTypes.USER_IDV_ANSWERED
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.USER_IDV_ANSWERED,
          errors: { idvError: true }
        });
      });
  };
}

export function getBookerVerifyUIToken() {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.USER_LOADING
    });
    api()
      .get('/bookers/identity_verifications/verify_ui_token')
      .then((response) => {
        const { token } = response.data;
        dispatch({
          type: actionTypes.USER_GET_VERIFYUI_TOKEN,
          token
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else if (Array.isArray(data.error)) {
            userErrors = { message: data.error.join(',') };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function setHasCalledIDV(hasCallIDV) {
  return (dispatch) => {
    dispatch({
      hasCallIDV,
      type: actionTypes.USER_SET_HAS_CALLED_IDV
    });
  };
}

export function resetIDVErrors() {
  return (dispatch, getState) => {
    const { errors } = getState().userReducer;
    if (errors && errors.idvError !== undefined) {
      delete errors.idvError;
    }
    dispatch({
      errors: { ...errors },
      type: actionTypes.USER_ERRORS
    });
  };
}

function getUserPayoutAccount() {
  return new Promise((resolve, reject) => {
    api()
      .get(`/provider/payout_account`)
      .then((response) => {
        const bankInfo = response.data;
        resolve(bankInfo);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export function getUserInfo() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .get(`/users/details`)
      .then(async (response) => {
        const { user } = response.data;
        let bankInfo = {};
        if (user.role === 'provider') {
          bankInfo = await getUserPayoutAccount();
        }
        let verified = false;
        let {
          primary_phone_number: phoneNumber,
          backup_phone_number: backupNumber
        } = user;
        if (phoneNumber && typeof phoneNumber === 'object') {
          verified = phoneNumber.verified;
          phoneNumber = phoneNumber.number;
        }
        if (backupNumber && typeof backupNumber === 'object') {
          backupNumber = backupNumber.number;
        }

        dispatch({
          user: {
            ...user,
            ...bankInfo,
            primary_phone_number_verified: verified,
            primary_phone_number: phoneNumber,
            backup_phone_number: backupNumber
          },
          type: actionTypes.USER_LOADED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function updateUserInfo(updatedUser) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    const user = { ...updatedUser };

    if (user.phoneNumber) {
      user.primary_phone_number = user.phoneNumber;
      delete user.phoneNumber;
    }

    if (user.backupNumber) {
      user.backup_phone_number = user.backupNumber;
      delete user.backupNumber;
    }
    api()
      .patch(`/users/update`, {
        user
      })
      .then(() => {
        const userWithKeys = { ...currentUser, ...user };
        dispatch({
          user: userWithKeys,
          type: actionTypes.USER_UPDATE
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function setSuccessfullyUpdatedFlag(updated) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_SET_FLAG_UPDATED,
      updated
    });
  };
}

export function sendPushNotificationSubscription({ token = '', platform }) {
  return (dispatch) => {
    const browser = getBrowser();
    api()
      .post(`/push_notification_subscriptions`, {
        device_token: token,
        device: platform,
        browser_or_platform_name: `${browser.name} ${browser.version}`
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function resetErrorsObject() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_RESET_ERRORS
    });
  };
}

export function deleteOneTimeToken() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_DELETE_ONE_TIME_TOKEN
    });
  };
}

export function confirmPhoneNumber(newPhoneNumber) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });
    api()
      .patch(`/provider/primary_phone_number`, {
        number: newPhoneNumber
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: actionTypes.USER_PHONE_NUMBER_CONFIRM
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function updateUserPayoutAccount(stripeData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .patch(`/provider/payout_account`, stripeData)
      .then((response) => {
        const updatedUser = response.data;
        dispatch({
          user: updatedUser,
          type: actionTypes.USER_UPDATE
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function resetPhoneNumberConfirm() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_PHONE_NUMBER_CONFIRM_RESET
    });
  };
}

export function sendVerificationCode(verificationCode, phoneNumber) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .patch(`/provider/primary_phone_number/verify`, {
        phone_number: {
          pin: verificationCode,
          number: phoneNumber
        }
      })
      .then((response) => {
        if (response.status === 200) {
          const { data = {} } = response;
          const { phone_number: phoneNumberUpdated = {} } = data;
          dispatch({
            type: actionTypes.USER_PHONE_NUMBER_UPDATED,
            phoneNumber: phoneNumberUpdated.number
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function resetPhoneNumberUpdated() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_PHONE_NUMBER_UPDATED_RESET
    });
  };
}

export function getTheAvailabilities() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });
    api()
      .get('/provider/availabilities')
      .then((response) => {
        const { data } = response || {};
        const providerAvailabilities = data.provider_availabilities || {};
        const availabilities = {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        };
        const daysOfWeek = Object.keys(availabilities);
        daysOfWeek.forEach((day) => {
          if (providerAvailabilities[day]) {
            providerAvailabilities[day].ranges.forEach((range) => {
              const newAvailability = {
                upper: range.end_hour,
                lower: range.start_hour,
                id: range.id
              };
              availabilities[day].push(newAvailability);
            });
          }
        });
        dispatch({
          type: actionTypes.USER_AVAILABILITIES,
          availabilities
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let availabilityErrors = {
          message: 'Something went wrong getting OPPORTUNITIES'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            availabilityErrors = { message: data.error };
          } else {
            availabilityErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: availabilityErrors
        });
      });
  };
}

export function sendTheAvailabilities(sentAvailabilities) {
  const availabilitiesToSend = [];
  const daysOfWeek = Object.keys(sentAvailabilities);
  daysOfWeek.forEach((day) => {
    sentAvailabilities[day].forEach((range) => {
      const newAvailability = {
        end_hour: range.upper,
        start_hour: range.lower,
        day_of_week: day
      };
      availabilitiesToSend.push(newAvailability);
    });
  });
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .patch('/provider/availabilities', {
        provider_availabilities: availabilitiesToSend
      })
      .then((response) => {
        const { data } = response || {};
        const providerAvailabilities = data.provider_availabilities || {};
        const availabilities = {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: []
        };
        const daysOfTheWeek = Object.keys(availabilities);
        daysOfTheWeek.forEach((day) => {
          availabilities[day] = [];
          if (
            providerAvailabilities[day] &&
            providerAvailabilities[day].ranges
          ) {
            providerAvailabilities[day].ranges.forEach((range) => {
              const newAvailability = {
                upper: range.end_hour,
                lower: range.start_hour,
                id: range.id
              };
              availabilities[day].push(newAvailability);
            });
          }
        });
        dispatch({
          type: actionTypes.USER_AVAILABILITIES_SAVED,
          availabilities
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let availabilityErrors = {
          message: 'Something went wrong getting AVAILABILITIES'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            availabilityErrors = { message: data.error };
          } else {
            availabilityErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: availabilityErrors
        });
      });
  };
}

export function updateTheAvailabilities(availabilities) {
  return (dispatch) => {
    dispatch({
      availabilities,
      type: actionTypes.USER_AVAILABILITIES
    });
  };
}

export function availabilitiesReset() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_AVAILABILITIES_RESET
    });
  };
}

export function updateUserLocally(user) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_UPDATE_LOCALLY,
      user
    });
  };
}

export function trackHowUserHeardAboutUs(hearAboutUsData) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_HEAR_ABOUT_US,
      hearAboutUs: hearAboutUsData
    });
  };
}

export function sendBookerSSN(ssn) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.USER_LOADING,
      loading: true
    });

    api()
      .post('/bookers/identity_verifications/ssn', { ssn })
      .then(() => {
        dispatch({
          type: actionTypes.USER_SSN_SENT
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export const requestDeleteUser = () =>
  api()
    .post('/users/request_delete')
    .then((response) => response)
    .catch((e) => console.error(e));
