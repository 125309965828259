import actionTypes from 'constants/actionTypes';
import { api } from 'api/connect';

export function getBookingsSummary() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_SUMMARY_LOADING,
      loading: true
    });

    api()
      .get('/provider/bookings?variant=summary')
      .then((response) => {
        const { data } = response || {};
        const { bookings } = data || {};
        dispatch({
          type: actionTypes.PROVIDER_BOOKINGS_SUMMARY_LOADED,
          bookings
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let bookingsErrors = {
          message: 'Something went wrong getting BOOKINGS'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            bookingsErrors = { message: data.error };
          } else {
            bookingsErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_BOOKINGS_ERRORS,
          errors: bookingsErrors
        });
      });
  };
}

export function getBookings({ variant = 'summary', offset = 0, limit = 20 }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_LOADING,
      loading: true
    });

    api()
      .get(
        `/provider/bookings?variant=${variant}&offset=${offset}&limit=${limit}`
      )
      .then((response) => {
        const { data } = response || {};
        const { bookings } = data || [];
        if (variant === 'upcoming') {
          dispatch({
            type: actionTypes.PROVIDER_UPCOMING_BOOKINGS_LOADED,
            bookings
          });
        }
        if (variant === 'past') {
          dispatch({
            type: actionTypes.PROVIDER_PAST_BOOKINGS_LOADED,
            bookings
          });
        }
        if (variant === 'summary') {
          dispatch({
            type: actionTypes.PROVIDER_BOOKINGS_SUMMARY_LOADED,
            bookings
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let bookingsErrors = {
          message: 'Something went wrong getting BOOKINGS'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            bookingsErrors = { message: data.error };
          } else {
            bookingsErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.PROVIDER_BOOKINGS_ERRORS,
          errors: bookingsErrors
        });
      });
  };
}

export function getBooking({ id = 0, showLoading = true }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_LOADING,
      loading: showLoading
    });

    return api()
      .get(`/provider/bookings/${id}`)
      .then((response) => {
        const { data } = response || {};
        dispatch({
          type: actionTypes.PROVIDER_BOOKING_LOADED,
          booking: data.event_booking || data.job
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let bookingsErrors = {
          message: 'Something went wrong getting bookings'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            bookingsErrors = { message: data.error };
          } else {
            bookingsErrors = { ...data.error };
          }
        }
        if (response.status === 404 || response.status === 403) {
          bookingsErrors = {
            notFound: true,
            message: 'Booking not found'
          };
        }
        dispatch({
          type: actionTypes.PROVIDER_BOOKINGS_ERRORS,
          errors: bookingsErrors
        });
      });
  };
}

export function resetSingleBookingLoaded() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_RESET_SINGLE_BOOKING_LOADED
    });
  };
}

export function providerCancelBooking({ booking, reason }) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_LOADING,
      loading: true
    });

    api()
      .post(`/provider/cancellations`, {
        booking_id: booking.id,
        job_provider_cancellation: {
          reason
        }
      })
      .then(() => {
        dispatch({
          type: actionTypes.PROVIDER_BOOKINGS_UPDATE,
          booking: { ...booking, status: 'canceled' }
        });
        dispatch({
          type: actionTypes.PROVIDER_BOOKING_CANCEL,
          canceled: true
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let bookingErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            bookingErrors = { message: data.error };
          } else {
            bookingErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.BOOKING_ERRORS,
          errors: bookingErrors
        });
      });
  };
}

export function resetBookingCanceled() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKING_RESET_CANCEL
    });
  };
}

export function reconcileBookingEndTime(reconcileInfo) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_LOADING,
      loading: true
    });

    api()
      .post(
        `/provider/bookings/${reconcileInfo.booking_id}/reconcile`,
        reconcileInfo
      )
      .then((response) => {
        const { data = {} } = response;
        const { job } = data;
        dispatch({
          type: actionTypes.PROVIDER_BOOKING_LOADED,
          booking: job
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let userErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            userErrors = { message: data.error };
          } else {
            userErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.USER_ERRORS,
          errors: userErrors
        });
      });
  };
}

export function wasReconciled(bookingId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKING_RECONCILED,
      booking_id: bookingId
    });
  };
}

export function updateBooking(booking) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.PROVIDER_BOOKINGS_UPDATE,
      booking
    });
  };
}
