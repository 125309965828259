import React from 'react';
import { IonLoading } from '@ionic/react';

const Loading = ({ isOpen, message = 'Please wait', duration }) => {
  if (isOpen) {
    return <IonLoading isOpen={isOpen} message={message} duration={duration} />;
  }
  return null;
};

export default Loading;
