import React, { useEffect } from 'react';
import { IonToast } from '@ionic/react';
import { innerTrendsMessageRegister } from 'utils/innerTrends';

const Toast = ({
  isOpen = false,
  message,
  duration = 3000,
  color = 'success',
  position = 'top',
  animated = true,
  close,
  cssClass,
  buttons
}) => {
  useEffect(
    function callInnerTrends() {
      if (isOpen) {
        innerTrendsMessageRegister({
          messageType: color,
          messageName: message,
          displayedMessage: message
        });
      }
    },
    [isOpen, color, message]
  );

  return (
    <IonToast
      cssClass={cssClass}
      color={color}
      isOpen={isOpen}
      position={position}
      duration={duration}
      message={message}
      animated={animated}
      buttons={buttons}
      onDidDismiss={() => close()}
    />
  );
};

export default Toast;
