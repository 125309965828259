import actionTypes from 'constants/actionTypes';

const initialState = {
  allShortlists: [], // it includes shared shortlists
  shortlists: [], // only my user's shortlists
  errors: {},
  loading: false,
  apiCalled: false,
  getAllShortlistsCalled: false,
  successfullyCreated: false,
  successfullyCloned: false,
  successfullyAdded: false,
  successfullyRemoved: false,
  successfullyDeleted: false
};

const shortlistReducer = (state = initialState, action) => {
  let allShortlists = state.allShortlists.slice(0);
  let shortlists = state.shortlists.slice(0);
  switch (action.type) {
    case actionTypes.SHORTLIST_LOADING:
      return {
        ...state,
        loading: action.loading,
        errors: {},
        successfullyCreated: false,
        successfullyDeleted: false,
        successfullyAdded: false,
        successfullyCloned: false,
        successfullyRemoved: false
      };
    case actionTypes.SHORTLIST_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors,
        successfullyCreated: false,
        successfullyAdded: false,
        successfullyRemoved: false
      };
    case actionTypes.SHORTLIST_LOADED:
      return {
        ...state,
        loading: false,
        allShortlists: action.shortlists.slice(0),
        shortlists: action.shortlists,
        apiCalled: true,
        getAllShortlistsCalled: true
      };
    case actionTypes.SHORTLIST_CREATE:
      return {
        ...state,
        loading: action.loading || false,
        allShortlists: allShortlists.concat([action.shortlist]),
        shortlists: shortlists.concat([action.shortlist]),
        successfullyCreated: true
      };
    case actionTypes.SHORTLIST_ALL_UPDATE:
      if (allShortlists.find((s) => s.uuid === action.shortlist.uuid)) {
        allShortlists = allShortlists.map((s) => {
          if (s.uuid === action.shortlist.uuid) {
            return action.shortlist;
          }
          return s;
        });
      } else {
        allShortlists.push(action.shortlist);
      }
      return {
        ...state,
        loading: false,
        allShortlists
      };
    case actionTypes.SHORTLIST_UPDATE:
      if (shortlists.find((s) => s.uuid === action.shortlist.uuid)) {
        shortlists = shortlists.map((s) => {
          if (s.uuid === action.shortlist.uuid) {
            return action.shortlist;
          }
          return s;
        });
      } else {
        shortlists.push(action.shortlist);
      }
      return {
        ...state,
        loading: false,
        shortlists
      };
    case actionTypes.SHORTLIST_DELETE:
      return {
        ...state,
        loading: false,
        shortlists: shortlists.filter((s) => s.uuid !== action.shortlistId),
        allShortlists: allShortlists.filter(
          (s) => s.uuid !== action.shortlistId
        ),
        successfullyDeleted: true
      };
    case actionTypes.SHORTLIST_CLONE:
      return {
        ...state,
        loading: false,
        successfullyCloned: true
      };
    case actionTypes.SHORTLIST_PROVIDER_ADDED:
      return {
        ...state,
        loading: false,
        successfullyAdded: true
      };
    case actionTypes.SHORTLIST_PROVIDER_REMOVED:
      return {
        ...state,
        loading: false,
        successfullyRemoved: true
      };
    case actionTypes.SHORTLIST_MODAL_OPEN:
      return {
        ...state,
        successfullyAdded: false,
        successfullyCreated: false,
        successfullyDeleted: false,
        successfullyRemoved: false,
        errors: {}
      };
    default:
      return state;
  }
};

export default shortlistReducer;
