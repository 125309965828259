import React from 'react';
import PropTypes from 'prop-types';
import { alertCircleOutline } from 'ionicons/icons';
import Icon from './Icon';

/* eslint-disable no-multi-str */
const ErrorMessage = ({
  className = '',
  message = 'Oops! It looks like there is some info missing that we need before we can move on.'
}) => {
  return (
    <p className={`error-message ${className}`}>
      <Icon icon={alertCircleOutline} />
      <span className="all-error-messages">
        {typeof message === 'string' && (
          <span className="message">{message}</span>
        )}
        {message.constructor === Array &&
          message.map((m) => (
            <span key={m} className="message">
              {m}
            </span>
          ))}
      </span>
    </p>
  );
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default ErrorMessage;
