import userReducer from 'provider/reducers/userReducer';
import addressReducer from 'provider/reducers/addressReducer';
import bookingReducer from 'provider/reducers/bookingReducer';
import opportunityReducer from 'provider/reducers/opportunityReducer';
import onboardingReducer from 'provider/reducers/onboardingReducer';
import skillsTestsReducer from 'provider/reducers/skillsTestsReducer';

const providerRootReducer = {
  userReducer,
  addressReducer,
  bookingReducer,
  opportunityReducer,
  onboardingReducer,
  skillsTestsReducer
};

export default providerRootReducer;
