import React from 'react';
import PropTypes from 'prop-types';
import { IonInput } from '@ionic/react';
import Label from './Label';
import ErrorMessage from './ErrorMessage';

const TextInput = ({
  readonly,
  reference,
  autoFocus,
  id = '',
  required,
  type = 'text',
  label,
  name,
  value = '',
  color,
  hasError,
  onChange,
  onClick,
  onBlur,
  onBlurThenChange,
  onKeyDown,
  className = '',
  labelClassName = '',
  placeholder,
  error,
  disabled,
  inputmode,
  onFocus,
  min,
  max,
  maxlength
}) => {
  return (
    <>
      {label && <Label className={labelClassName}>{label}</Label>}
      <IonInput
        autocomplete="on"
        readonly={readonly}
        ref={reference}
        autofocus={autoFocus}
        id={id}
        name={name}
        placeholder={placeholder}
        className={`callemmy-text-input ${className} ${
          hasError ? 'has-error' : ''
        }`}
        required={required}
        type={type}
        value={value}
        color={color}
        onBlur={(e) => {
          if (onBlurThenChange && typeof onBlurThenChange === 'function') {
            onBlurThenChange(e);
            if (typeof onChange === 'function') {
              onChange(e);
            }
          } else if (onBlur && typeof onBlur === 'function') {
            onBlur(e);
          }
        }}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onIonChange={onChange}
        disabled={disabled}
        inputmode={inputmode}
        onFocus={onFocus}
        min={min}
        max={max}
        maxlength={maxlength}
      />
      {error && <ErrorMessage message={error} />}
    </>
  );
};

/* eslint-disable react/forbid-prop-types */
TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  reference: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  autofocus: PropTypes.bool,
  value: PropTypes.string,
  color: PropTypes.string,
  hasError: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onBlurThenChange: PropTypes.func, // Is used to make autocomplete work when onBlur is defined
  onClick: PropTypes.func,
  readonly: PropTypes.bool,
  labelClassName: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ]),
  inputmode: PropTypes.string
};

export default TextInput;
