import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Pusher from 'pusher-js/with-encryption';
import notificationTypes from 'utils/notificationTypes';
import {
  getUrgentNotifications,
  closeUrgentNotification,
  addSingleUrgentNotification,
  removeUrgentActionOnBE
} from 'common/actions/notificationActions';
import Modal from 'common/Modal';
import Button from 'common/Button';
import TextInput from 'common/TextInput';
import InformationBox from 'common/InformationBox';

const Notifications = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const pusher = useRef();

  const { notifications = [], loading, showNotifications } = useSelector(
    (state) => state.notificationReducer
  );
  const { user, isLoggedIn, hasAcceptedTerms } = useSelector(
    (state) => state.userReducer
  );
  const { role } = user;

  const { allBookings, bookings } = useSelector(
    (state) => state.provider.bookingReducer
  );

  // eslint-disable-next-line camelcase
  const { current_booking: { job } = {} } = bookings;

  const [currentNotification, setCurrentNotification] = useState();
  const [currentModalData, setCurrentModalData] = useState();

  useEffect(
    function setNotification() {
      if (notifications.length === 0) {
        setCurrentNotification(null);
        setCurrentModalData(null);
      }

      if (notifications.length > 0 && !loading && showNotifications) {
        const {
          event_name: eventName,
          event_data: eventData
        } = notifications[0];

        const bookingNotification =
          allBookings?.find((booking) => booking.id === eventData.booking_id) ||
          (job && job.id === eventData.booking_id);

        const status = bookingNotification?.status;

        if (eventName !== 'second_checkin') {
          setCurrentNotification(notifications[0]);
          const modalData = notificationTypes({
            history,
            dispatch,
            role,
            notification: notifications[0]
          });
          setCurrentModalData(modalData);
        } else if (
          eventName === 'second_checkin' &&
          status &&
          status !== 'started' &&
          status !== 'concluded' &&
          status !== 'finalized'
        ) {
          setCurrentNotification(notifications[0]);
          const modalData = notificationTypes({
            history,
            dispatch,
            role,
            notification: notifications[0]
          });
          setCurrentModalData(modalData);
        } else {
          dispatch(closeUrgentNotification(notifications[0].id, 0));
          dispatch(removeUrgentActionOnBE(notifications[0].id));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications]
  );

  const [inputs, setInputs] = useState({});
  useEffect(
    function loadInputValues() {
      const { form = [] } = currentModalData || {};
      if (form && form.length > 0) {
        form.forEach((input) => {
          setInputs((prevState) => ({ ...prevState, [input.name]: '' }));
        });
      }
    },
    [currentModalData]
  );

  useEffect(
    function getUrgentNotificationsIfUserLogsIn() {
      if (isLoggedIn && hasAcceptedTerms) {
        dispatch(getUrgentNotifications());
      }
    },
    [isLoggedIn, dispatch, hasAcceptedTerms]
  );

  useEffect(
    function initializePusher() {
      pusher.current = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER
      });

      if (isLoggedIn && hasAcceptedTerms) {
        const channel = pusher.current.subscribe(
          `user-${user.uuid}-urgent-actions`
        );
        channel.bind('urgent-actions', (notification) => {
          dispatch(addSingleUrgentNotification(notification));
        });
      }

      return function closePusherConnection() {
        pusher.current.disconnect();
      };
    },
    [user, dispatch, isLoggedIn, hasAcceptedTerms]
  );

  if (currentNotification && showNotifications) {
    const notification = currentNotification;
    const {
      title,
      content,
      buttons = [],
      form = [],
      hasCloseButton,
      waitAfterClose,
      showCloseButton,
      hideModal,
      warning
    } = currentModalData;

    return (
      <Modal
        mini
        showModal
        backdropDismiss={false}
        showCloseButton={showCloseButton}
        hideModal={hideModal}
        title={title}
        subtitle={`1 out of ${notifications.length}`}
        className="modal-urgent-notifications"
      >
        <div className="user-notification modal-body">
          <div className="modal-content">
            <p className="text-content">{content}</p>
            {form && form.length > 0 && (
              <div className="modal-form">
                {form
                  .filter((input) => input.name)
                  .map((input) => (
                    <TextInput
                      key={input.name}
                      label={input.label}
                      type={input.type || 'text'}
                      value={inputs[input.name]}
                      onChange={(e) => {
                        setInputs((prevState) => ({
                          ...prevState,
                          [input.name]: e.detail.value
                        }));
                      }}
                    />
                  ))}
              </div>
            )}
            {warning && <InformationBox>{warning}</InformationBox>}
          </div>
          <div
            className={
              currentNotification.event_name === 'booking_concluded'
                ? 'modal-bottom-buttons-booking-concluded'
                : 'modal-bottom-buttons'
            }
          >
            {buttons.map((button) => (
              <Button
                key={button.id}
                className={
                  currentNotification.event_name === 'booking_concluded'
                    ? `notification-modal-button-booking-concluded-${button.color}`
                    : `notification-modal-button notification-modal-button-${button.color}`
                }
                onClick={() => button.action(inputs)}
              >
                {button.title}
              </Button>
            ))}
            {hasCloseButton && (
              <Button
                key="cancel"
                className="cancel-button"
                onClick={() => {
                  dispatch(
                    closeUrgentNotification(notification.id, waitAfterClose)
                  );
                }}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
  return null;
};

export default Notifications;
