const dataLayerTrends = dataLayer || [];

export const innerTrendsUserRegister = ({ id, email, createdAt }) => {
  dataLayerTrends.push({
    user: {
      id,
      email,
      created_at: createdAt
    }
  });
};

export const innerTrendsMessageRegister = ({
  messageType,
  messageName,
  displayedMessage
}) => {
  dataLayerTrends.push({
    userMessage: {
      messageType,
      messageName,
      displayedMessage
    }
  });
};
