import { StreamChat } from 'stream-chat';
import { api } from 'api/connect';

let chatClient = null;
let tokens = {};
let chatUser = null;

function listenForEvents(sendInfo, channel) {
  if (channel && typeof channel.on === 'function') {
    channel.on((event) => {
      sendInfo({ event, channel });
    });
  }
}

async function getTokens() {
  if (tokens.streamToken && tokens.deviceToken) {
    return Promise.resolve(tokens);
  }
  try {
    const response = await api().get('/stream_chats/get_token');
    const { data = {} } = response;
    return Promise.resolve(data);
  } catch (err) {
    return Promise.resolve({});
  }
}

async function createChatRoom(bookingId) {
  try {
    await api().post('/stream_chats/create_booking_chat', {
      booking_id: bookingId
    });
    return Promise.resolve(true);
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function connectChatUser(user) {
  try {
    chatUser = await chatClient.connectUser(
      {
        id: user.uuid,
        name: user.first_name || user.email
      },
      tokens.stream_token
    );
    return Promise.resolve(chatUser);
  } catch (err) {
    chatUser = null;
    return Promise.resolve(null);
  }
}

export async function getChatChannel(bookingId) {
  const chatPrefix =
    process.env.REACT_APP_CHAT_ENV || process.env.NODE_ENV || 'callemmy';
  const filter = { type: 'messaging', id: `${chatPrefix}-${bookingId}` };
  try {
    let channels = await chatClient.queryChannels(filter);
    if (channels.length > 0) {
      return Promise.resolve(channels[0]);
    }
    await createChatRoom(bookingId);
    channels = await chatClient.queryChannels(filter);
    if (channels.length > 0) {
      return Promise.resolve(channels[0]);
    }
    return Promise.resolve({});
  } catch (err) {
    return Promise.resolve({});
  }
}

async function connectChat(user) {
  try {
    chatClient = new StreamChat(process.env.REACT_APP_STREAM_API_KEY);
    tokens = await getTokens();
    await connectChatUser(user);
    return Promise.resolve({ chatUser });
  } catch (err) {
    return Promise.resolve({});
  }
}

export async function loadChat(user, chatID, sendInfo) {
  await connectChat(user);
  const channel = await getChatChannel(chatID);
  const error =
    Object.keys(channel).length === 0 || Object.keys(chatUser).length === 0
      ? 'Unable to load chat'
      : null;
  listenForEvents(sendInfo, channel);
  if (tokens.device_token) {
    chatClient.addDevice(tokens.device_token, 'firebase', user.uuid);
  }

  return Promise.resolve({ chatClient, chatUser, channel, error });
}

export function disconnectChat() {
  if (chatClient) {
    chatClient.disconnect();
  }
}

export async function getChatClient(user) {
  if (!chatClient) {
    await connectChat(user);
  }
  return Promise.resolve(chatClient);
}

export function confirmThirtyMinChatMessage(id) {
  api()
    .post(`booking_communications_records/${id}/chat_sent_post_booking_start`)
    .catch((e) => {
      const { response = {} } = e;
      const { data = {} } = response;
      let chatErrors = { message: 'Something went wrong' };
      if (data.error) {
        if (typeof data.error === 'string') {
          chatErrors = { message: data.error };
        } else {
          chatErrors = { ...data.error };
        }
      }
      console.log(chatErrors);
    });
}
