import actionTypes from 'constants/actionTypes';

const initialState = {
  review: {},
  reviewsForMultiProviderBooking: [],
  errors: {},
  loading: false,
  successfullyReviewed: false
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REVIEW_UPDATE:
      return {
        ...state,
        loading: false,
        review: { ...action.review },
        errors: {}
      };
    case actionTypes.REVIEW_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.errors
      };
    case actionTypes.REVIEW_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.REVIEW_SENT:
      return {
        ...state,
        successfullyReviewed: true,
        loading: false
      };
    case actionTypes.REVIEW_RESET:
      return {
        ...state,
        successfullyReviewed: false,
        review: {},
        errors: {}
      };
    case actionTypes.REVIEW_RESET_SUCCESSFULLY_REVIEWED:
      return {
        ...state,
        successfullyReviewed: false
      };
    default:
      return state;
  }
};

export default reviewReducer;
