import React, { useEffect } from 'react';
import { IonModal } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import Icon from './Icon';

const Modal = ({
  showModal,
  hideModal = () => null,
  title,
  subtitle,
  label,
  className = '',
  children,
  backdropDismiss = true,
  onDidDismiss,
  mini,
  showCloseButton = true,
  id = '',
  isChat
}) => {
  useEffect(
    function disableJiraHelpWidget() {
      const jiraWidget = document.getElementById('jsd-widget');
      if (jiraWidget) {
        if (showModal) {
          jiraWidget.style.display = 'none';
        } else {
          jiraWidget.style.display = 'block';
        }
      }

      return () => {
        if (jiraWidget) jiraWidget.style.display = 'block';
      };
    },
    [showModal]
  );

  return (
    <IonModal
      id={id}
      className={`modal ${className} ${mini ? 'mini' : ''}`}
      isOpen={showModal}
      backdropDismiss={backdropDismiss}
      onDidDismiss={onDidDismiss || hideModal}
    >
      {(title || label || subtitle || showCloseButton) && (
        <div className={`${mini ? 'mini-modal-header' : 'modal-header'}`}>
          <div className="modal-title">
            <h2>{title || label}</h2>
            {mini && subtitle && <p className="subtitle">{subtitle}</p>}
          </div>
          {(mini || isChat) && showCloseButton && (
            <button
              className="close-modal"
              type="button"
              onClick={() => hideModal()}
            >
              <Icon icon={closeCircle} />
            </button>
          )}
        </div>
      )}
      {showModal && children}
    </IonModal>
  );
};

export default Modal;
