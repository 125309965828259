import actionTypes from 'constants/actionTypes';

const initialState = {
  user: {},
  hasGotUserInfo: false,
  successfullyUpdated: false,
  hasAcceptedTerms: false,
  hasCheckedTerms: false,
  errors: {},
  loading: false,
  isLoggedIn: false,
  hasSignedOut: false,
  passwordChanged: false,
  hasCalledIDCheck: false,
  IDCheckNeeded: true,
  hasCalledIDV: false,
  hasAnsweredIDV: 0,
  idv: [],
  providerIdv: [],
  successfullyAnswered: false,
  gotVerifyUIToken: false,
  oneTimeToken: '',
  onboarding: false,
  phoneNumberConfirm: false,
  phoneNumberUpdated: false,
  availabilities: {},
  availabilitiesLoaded: false,
  availabilitiesSaved: false,
  authenticatingUserCreated: false,
  authenticatingUserConsent: false,
  gotQuiz: false,
  providerIDVQuizData: {},
  userAccessCode: '',
  quizSuccessfullySent: false,
  ssnSent: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.USER_LOGIN:
      return {
        ...state,
        user: { ...action.user },
        isLoggedIn: true,
        loading: false,
        hasSignedOut: false,
        errors: {},
        hasAcceptedTerms: action.user && action.user.tos_accepted,
        hasCheckedTerms: action.user && action.user.tos_accepted
      };
    case actionTypes.USER_LOGOUT:
      return {
        ...state,
        hasSignedOut: true,
        loading: false
      };
    case actionTypes.USER_RESET_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case actionTypes.USER_CHECK_TERMS:
      return {
        ...state,
        hasAcceptedTerms: action.hasAcceptedTerms,
        hasCheckedTerms: true,
        loading: false,
        errors: {}
      };
    case actionTypes.USER_CHECK_IDV:
      return {
        ...state,
        IDCheckNeeded: action.checkNeeded,
        successfullyAnswered: !action.checkNeeded,
        hasCalledIDCheck: true,
        loading: false,
        errors: {}
      };
    case actionTypes.USER_IDV_LOADED:
      return {
        ...state,
        hasCalledIDV: true,
        idv: action.idv,
        loading: false,
        errors: {}
      };
    case actionTypes.USER_SET_HAS_CALLED_IDV:
      return {
        ...state,
        hasCalledIDV: action.hasCalledIDV,
        loading: false,
        errors: {}
      };
    case actionTypes.USER_IDV_ANSWERED:
      return {
        ...state,
        hasCalledIDV: true,
        hasAnsweredIDV: state.hasAnsweredIDV + 1,
        successfullyAnswered: true,
        loading: false,
        errors: action.errors || {}
      };
    case actionTypes.USER_IDV_ERRORS:
      return {
        ...state,
        hasAnsweredIDV: action.idv_attempts,
        loading: false,
        errors: action.errors || {}
      };
    case actionTypes.USER_GET_VERIFYUI_TOKEN:
      return {
        ...state,
        loading: false,
        errors: action.errors || {},
        gotVerifyUIToken: true,
        verifyUIToken: action.token
      };
    case actionTypes.USER_PASSWORD_CHANGE:
      return {
        ...state,
        loading: false,
        passwordChanged: true,
        errors: {}
      };
    case actionTypes.USER_RESET_PASSWORD_CHANGED:
      return {
        ...state,
        passwordChanged: false
      };
    case actionTypes.USER_LOADED:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.user },
        hasGotUserInfo: true,
        errors: {}
      };
    case actionTypes.USER_UPDATE:
      return {
        ...state,
        loading: false,
        user: { ...state.user, ...action.user },
        successfullyUpdated: true,
        errors: {}
      };
    case actionTypes.USER_UPDATE_LOCALLY:
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          ...action.user,
          primary_phone_number_verified:
            action.user.primary_phone_number ===
              state.user.primary_phone_number &&
            state.user.primary_phone_number_verified
        },
        errors: {}
      };
    case actionTypes.USER_SET_FLAG_UPDATED:
      return {
        ...state,
        successfullyUpdated: action.updated
      };
    case actionTypes.USER_LOADING:
      return {
        ...state,
        passwordChanged: false,
        successfullyUpdated: false,
        hasSignedOut: false,
        onTimeToken: '',
        loading: action.loading
      };
    case actionTypes.USER_ERRORS:
      return {
        ...state,
        errors: action.errors,
        passwordChanged: false,
        loading: false,
        hasSignedOut: false
      };
    case actionTypes.USER_TERMS_OF_SERVICE_ERRORS:
      return {
        ...state,
        errors: action.errors,
        passwordChanged: false,
        loading: false,
        hasSignedOut: false
      };
    case actionTypes.USER_PHONE_NUMBER_CONFIRM:
      return {
        ...state,
        phoneNumberConfirm: true,
        loading: false
      };
    case actionTypes.USER_PHONE_NUMBER_CONFIRM_RESET:
      return {
        ...state,
        phoneNumberConfirm: false
      };
    case actionTypes.USER_PHONE_NUMBER_UPDATED:
      return {
        ...state,
        phoneNumberUpdated: true,
        loading: false,
        user: {
          ...state.user,
          primary_phone_number: action.phoneNumber,
          primary_phone_number_verified: true
        }
      };
    case actionTypes.USER_PHONE_NUMBER_UPDATED_RESET:
      return {
        ...state,
        phoneNumberUpdated: false
      };
    case actionTypes.USER_AVAILABILITIES:
      return {
        ...state,
        availabilities: action.availabilities,
        availabilitiesLoaded: true,
        loading: false
      };
    case actionTypes.USER_AVAILABILITIES_SAVED:
      return {
        ...state,
        availabilities: action.availabilities,
        availabilitiesSaved: true,
        loading: false,
        errors: {}
      };
    case actionTypes.USER_AVAILABILITIES_RESET:
      return {
        ...state,
        availabilitiesSaved: false
      };
    case actionTypes.USER_CREATE_AUTHENTICATING_USER:
      return {
        ...state,
        loading: false,
        userAccessCode: action.userAccessCode,
        authenticatingUserCreated: true
      };
    case actionTypes.USER_CONSENT_AUTHENTICATING_USER:
      return {
        ...state,
        authenticatingUserConsent: action.success
      };
    case actionTypes.USER_GET_AUTHENTICATING_QUIZ:
      return {
        ...state,
        providerIDVQuizData: action.data,
        idv: action.identityQuestions,
        loading: false,
        gotQuiz: true
      };
    case actionTypes.USER_SUBMIT_AUTHENTICATING_QUIZ:
      return {
        ...state,
        quizSuccessfullySent: true
      };
    case actionTypes.USER_SSN_SENT:
      return {
        ...state,
        ssnSent: true
      };
    default:
      return state;
  }
};

export default userReducer;
