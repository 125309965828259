function addTopNotchWhenJiraFormOpens(jiraWidget) {
  const updatedJiraWidget = jiraWidget;
  if (updatedJiraWidget.style.height !== '100%') {
    updatedJiraWidget.style.top = 'env(safe-area-inset-top)';
  } else {
    updatedJiraWidget.style.top = '';
  }
}

function addJiraWidgetClickEvent() {
  const jiraWidget = document.getElementById('jsd-widget');
  if (jiraWidget) {
    const frameContent = jiraWidget.contentWindow.document;
    const div = frameContent.querySelector('#react-root');
    div.addEventListener('click', () =>
      addTopNotchWhenJiraFormOpens(jiraWidget)
    );
    return true;
  }
  return false;
}

export default function addJiraWidgetObserver() {
  const observer = new MutationObserver(function(mutations, me) {
    const eventAdded = addJiraWidgetClickEvent();
    if (eventAdded) {
      me.disconnect();
    }
  });

  observer.observe(document, {
    childList: true,
    subtree: true
  });
}
