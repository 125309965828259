import actionTypes from 'constants/actionTypes';
import { api } from '../../api/connect';

export function startBooking(serviceId, isChildCare) {
  return (dispatch) =>
    dispatch({
      type: actionTypes.BOOKING_START,
      serviceId,
      isChildCare
    });
}

export function resetBooking() {
  return (dispatch) =>
    dispatch({
      type: actionTypes.BOOKING_RESET
    });
}

export function resetBookingErrors() {
  return (dispatch) =>
    dispatch({
      type: actionTypes.BOOKING_RESET_ERRORS
    });
}

export function changeBookingStep(newStep) {
  let step = newStep;
  if (typeof step !== 'number') step = 1;
  return (dispatch) =>
    dispatch({
      step,
      type: actionTypes.BOOKING_CHANGE_STEP
    });
}

export function updateBooking(booking) {
  return (dispatch) =>
    dispatch({
      type: actionTypes.BOOKING_UPDATE,
      booking: { ...booking }
    });
}

export function updateBookingAttribute(attributeName, attributeValue) {
  return (dispatch) =>
    dispatch({
      attributeName,
      attributeValue,
      type: actionTypes.BOOKING_UPDATE_ATTRIBUTE
    });
}

export function checkCouponAndGiftCode(code) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.BOOKING_COUPON_LOADING,
      loading: true
    });
    try {
      const couponResponse = (await api().get(`/coupons/${code}`)) || {};
      const { data = {} } = couponResponse;
      const { coupon = {} } = data;
      if (coupon.amount) {
        if (coupon.valid) {
          dispatch({
            coupon: { ...coupon, id: coupon.code },
            type: actionTypes.BOOKING_COUPON
          });
        } else if (coupon.expired) {
          dispatch({
            error: 'Coupon already expired',
            type: actionTypes.BOOKING_COUPON_ERROR
          });
        } else {
          dispatch({
            error: "This coupon isn't a valid coupon",
            type: actionTypes.BOOKING_COUPON_ERROR
          });
        }
      } else {
        dispatch({
          error: 'Coupon does not exist',
          type: actionTypes.BOOKING_COUPON_ERROR
        });
      }
    } catch (errorCoupon) {
      try {
        const giftResponse =
          (await api().get(`/gift_certificates/${code}`)) || {};
        const { data: giftData } = giftResponse;
        const { gift_certificate: gift } = giftData || {};
        if (gift && gift.remaining_amount_in_cents) {
          const remaining = parseInt(gift.remaining_amount_in_cents, 10);
          dispatch({
            gift_certificate: {
              ...gift,
              amount: remaining,
              type: 'cents',
              expired: remaining === 0
            },
            type: actionTypes.BOOKING_GIFT_CERTIFICATE
          });
        } else {
          dispatch({
            error: 'Coupon does not exist',
            type: actionTypes.BOOKING_COUPON_ERROR
          });
        }
      } catch (error) {
        const { response = {} } = error;
        if (response.status === 404) {
          dispatch({
            error: 'Coupon does not exist',
            type: actionTypes.BOOKING_COUPON_ERROR
          });
        } else {
          dispatch({
            error: 'Something went wrong',
            type: actionTypes.BOOKING_COUPON_ERROR
          });
        }
      }
    }
  };
}

export function createBooking(booking) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.BOOKING_LOADING,
      loading: true
    });

    api()
      .post('/bookers/bookings', { booking })
      .then((response) => {
        const { data = {} } = response;
        const { job = {} } = data || {};
        const updatedBooking = {
          ...booking,
          id: job.id,
          status: job.status,
          amount: job.amount
        };
        dispatch({
          type: actionTypes.BOOKING_SENT,
          booking: updatedBooking
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let bookingErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            bookingErrors = { message: data.error };
          } else {
            bookingErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.BOOKING_ERRORS,
          errors: bookingErrors
        });
      });
  };
}

export function reportBookingNoShow({ booking, bookingType, cancelAction }) {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.BOOKING_LOADING,
      loading: true
    });
    dispatch({
      type: actionTypes.BOOKING_NO_SHOW_FLAG,
      noShowReported: false
    });

    api()
      .post(`/bookers/bookings/${booking.id}/report_no_show`, {
        cancel_action: cancelAction,
        booking_type: bookingType
      })
      .then(() => {
        if (cancelAction !== 'find_a_replacement') {
          dispatch({
            type: actionTypes.BOOKING_CANCEL,
            canceled: true
          });
        }
        dispatch({
          type: actionTypes.BOOKING_NO_SHOW_FLAG,
          noShowReported: true
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let bookingErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            bookingErrors = { message: data.error };
          } else {
            bookingErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.BOOKING_ERRORS,
          errors: bookingErrors
        });
      });
  };
}

export function updateSingleBookingLoadedFlag(singleBookingLoaded) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.BOOKINGS_UPDATE_SINGLE_BOOKING_LOADED_FLAG,
      singleBookingLoaded
    });
  };
}
