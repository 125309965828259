import actionTypes from 'constants/actionTypes';

const initialState = {
  bookings: {
    upcoming: {
      count: 0,
      booking_records: []
    },
    past: {
      count: 0,
      booking_records: []
    }
  },
  allBookings: [],
  booking: {},
  errors: {},
  loading: false,
  apiCalled: false,
  singleBookingLoaded: false,
  upcomingBookingsLoaded: false,
  loadingSummary: false,
  bookingsSummaryLoaded: false,
  pastBookingsLoaded: false,
  successfullyCanceled: false,
  reconcile: {}
};

const bookingCategoryFromStatus = {
  started: 'current_booking',
  expired: 'past',
  canceled: 'past',
  concluded: 'past',
  finalized: 'past',
  errored: 'past',
  engaged: 'upcoming'
};

const findBookingAndStatus = (bookings, bookingId) => {
  let booking = null;
  let status = null;
  const keys = Object.keys(bookings);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    if (key === 'current_booking') {
      if (bookings[key].job && bookings[key].job.id === bookingId) {
        status = key;
        booking = bookings[key].job;
        break;
      }
    } else {
      booking = bookings[key].booking_records.find((b) => b.id === bookingId);
      if (booking) {
        status = key;
        break;
      }
    }
  }

  return { booking, status };
};

const getAllBookingRecords = (bookings) => {
  const { upcoming, past } = bookings;
  return upcoming.booking_records.concat(past.booking_records);
};

const updateBookings = (bookings, booking) => {
  const currentCategory = bookingCategoryFromStatus[booking.status];
  const { status: oldCategory } = findBookingAndStatus(bookings, booking.id);
  if (oldCategory === currentCategory || !oldCategory || !currentCategory) {
    return { ...bookings };
  }

  if (oldCategory === 'current_booking') {
    const updatedBookingRecords = bookings[
      currentCategory
    ].booking_records.slice(0);
    updatedBookingRecords.unshift(booking);
    return {
      ...bookings,
      current_booking: {},
      [currentCategory]: {
        ...bookings[currentCategory],
        booking_records: updatedBookingRecords
      }
    };
  }

  const oldCategoryBookings = bookings[oldCategory].booking_records.filter(
    (job) => job.id !== booking.id
  );
  if (currentCategory === 'current_booking') {
    return {
      ...bookings,
      current_booking: { job: { ...booking } },
      [oldCategory]: {
        ...bookings[oldCategory],
        booking_records: oldCategoryBookings
      }
    };
  }

  const updatedBookingRecords = bookings[currentCategory].booking_records.slice(
    0
  );
  updatedBookingRecords.unshift(booking);
  return {
    ...bookings,
    [currentCategory]: {
      ...bookings[currentCategory],
      booking_records: updatedBookingRecords
    },
    [oldCategory]: {
      ...bookings[oldCategory],
      booking_records: oldCategoryBookings
    }
  };
};

const updateSingleBooking = (allBookings, booking) => {
  const index = allBookings.findIndex((b) => b.id === booking.id);
  if (index > -1) {
    return allBookings.map((b) => {
      if (b.id === booking.id) {
        return booking;
      }
      return b;
    });
  }
  return allBookings.concat([booking]);
};

const addToAllBookings = (allBookings, newBookings) => {
  const updatedBookings = allBookings.filter(
    (booking) => !newBookings.find((b) => b.id === booking.id)
  );
  return updatedBookings.concat(newBookings);
};

const loadSingleTypeBookings = (bookings, newBookings, type) => {
  let updatedBookings = { ...bookings };

  if (type) {
    // Go through the current list of bookings to update it or add new ones
    let updatedBookingRecords = bookings[type].booking_records.filter(
      (booking) =>
        !newBookings.find(
          (b) =>
            b.booking_id === booking.booking_id || b.id === booking.booking_id
        )
    );
    // API returns id instead of booking_id when from booking detail. Adding
    // booking_id to the object
    const newBookingsWithBookingId = newBookings.map((booking) => {
      if (!booking.booking_id) {
        return {
          ...booking,
          booking_id: booking.id
        };
      }
      return booking;
    });
    updatedBookingRecords = updatedBookingRecords.concat(
      newBookingsWithBookingId
    );
    updatedBookings = {
      ...updatedBookings,
      [type]: {
        ...updatedBookings[type],
        booking_records: updatedBookingRecords
      }
    };
  }
  return updatedBookings;
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROVIDER_BOOKINGS_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.PROVIDER_BOOKINGS_SUMMARY_LOADING:
      return {
        ...state,
        loadingSummary: action.loading
      };
    case actionTypes.PROVIDER_BOOKINGS_ERRORS:
      return {
        ...state,
        errors: action.errors,
        loading: false
      };
    case actionTypes.PROVIDER_UPCOMING_BOOKINGS_LOADED:
      return {
        ...state,
        bookings: loadSingleTypeBookings(
          state.bookings,
          action.bookings,
          'upcoming'
        ),
        allBookings: addToAllBookings(state.allBookings, action.bookings),
        loading: false,
        apiCalled: true,
        upcomingBookingsLoaded: true
      };
    case actionTypes.PROVIDER_PAST_BOOKINGS_LOADED:
      return {
        ...state,
        bookings: loadSingleTypeBookings(
          state.bookings,
          action.bookings,
          'past'
        ),
        allBookings: addToAllBookings(state.allBookings, action.bookings),
        loading: false,
        apiCalled: true,
        pastBookingsLoaded: true
      };
    case actionTypes.PROVIDER_BOOKING_LOADED:
      return {
        ...state,
        loading: false,
        booking: action.booking,
        allBookings: updateSingleBooking(state.allBookings, action.booking),
        singleBookingLoaded: true
      };
    case actionTypes.PROVIDER_BOOKINGS_RESET_SINGLE_BOOKING_LOADED:
      return {
        ...state,
        singleBookingLoaded: false,
        booking: {},
        errors: {}
      };
    case actionTypes.PROVIDER_BOOKINGS_SUMMARY_LOADED:
      return {
        ...state,
        bookings: { ...action.bookings },
        allBookings: getAllBookingRecords(action.bookings),
        loadingSummary: false,
        apiCalled: true,
        bookingsSummaryLoaded: true
      };
    case actionTypes.NOTIFICATION_FINALIZED:
      return {
        ...state,
        loading: false
      };
    case actionTypes.PROVIDER_BOOKINGS_UPDATE:
      return {
        ...state,
        bookings: updateBookings(state.bookings, action.booking),
        allBookings: updateSingleBooking(state.allBookings, action.booking),
        booking:
          state.booking && state.booking.id === action.booking.id
            ? { ...action.booking }
            : state.booking
      };
    case actionTypes.PROVIDER_BOOKING_CANCEL:
      return {
        ...state,
        successfullyCanceled: action.canceled,
        loading: false
      };
    case actionTypes.PROVIDER_BOOKING_RESET_CANCEL:
      return {
        ...state,
        successfullyCanceled: false,
        loading: false
      };
    case actionTypes.PROVIDER_BOOKING_RECONCILED:
      return {
        ...state,
        reconcile: { ...state.reconcile, [action.booking_id]: true }
      };
    default:
      return state;
  }
};

export default bookingReducer;
