const urgentActionsChannel = (userUUID) => {
  return `user-${userUUID}-urgent-actions`;
};

const eventsChannel = (userUUID) => {
  return `user-${userUUID}-events`;
};

export default function channelIDs(userUUID) {
  if (!userUUID) {
    return {};
  }
  return {
    URGENT_ACTIONS: urgentActionsChannel(userUUID),
    EVENTS_CHANNEL: eventsChannel(userUUID)
  };
}
