import Pusher from 'pusher-js/with-encryption';

let pusher = null;

export const channels = {};

export function initializeChannels() {
  pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER
  });
  return pusher;
}

export function subscribeToChannel(channelID) {
  if (!pusher) {
    initializeChannels();
  }

  channels[channelID] = pusher.subscribe(channelID);
  return channels[channelID];
}

export function getChannelByID(channelID) {
  return channels[channelID];
}
