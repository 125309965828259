import React, { useEffect } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import TopNav from 'booker/components/navs/TopNav';
import Footer from 'common/Footer';
import BookerBottomNav from 'booker/components/navs/BottomNav';
import ProviderBottomNav from 'provider/components/navs/BottomNav';
import errorImage from 'assets/images/error-image.webp';

const bookerErrorLinks = () => (
  <>
    <li>
      <Link to="/booking/1">Book a Provider</Link>
    </li>
    <li>
      <Link to="/bookings">View Your Bookings</Link>
    </li>
    <li>
      <Link to="/providers">Browse Providers Near You</Link>
    </li>
    <li>
      <Link to="/faq">Get Help with Something Else</Link>
    </li>
  </>
);

const providerErrorLinks = () => (
  <>
    <li>
      <Link to="/provider/bookings">View Your Bookings</Link>
    </li>
    <li>
      <Link to="/provider/opportunities">View Your Opportunities</Link>
    </li>
    <li>
      <Link to="/provider/my-schedule">Change Your Schedule</Link>
    </li>
    <li>
      <Link to="/provider/faq">Get Help with Something Else</Link>
    </li>
  </>
);

const defaultErrorLinks = () => (
  <>
    <li>
      <a href="https://www.callemmy.com">Call Emmy Homepage</a>
    </li>
    <li>
      <a href="https://www.callemmy.com/how-it-works">How It Works</a>
    </li>
    <li>
      <a href="https://www.callemmy.com/babysitter-background-checks">
        Screening Process
      </a>
    </li>
    <li>
      <Link to="/sign-in">Sign in</Link>
    </li>
  </>
);

const ErrorPage = ({ resetError = () => null, currentPage }) => {
  const { user, isLoggedIn } = useSelector((state) => state.userReducer);
  const entryChoice = localStorage.getItem('callemmy-entry-choice');
  const isBooker = user.role === 'booker' || entryChoice === 'booker';
  const isProvider = user.role === 'provider' || entryChoice === 'provider';

  const location = useLocation();
  useEffect(
    function resetErrorWhenPageChanges() {
      if (currentPage !== location.pathname) {
        resetError();
      }
    },
    [location, resetError, currentPage]
  );

  return (
    <IonPage>
      <IonContent fullscreen>
        <TopNav fullPage authenticated={isLoggedIn} errorPage />
        <div className="page-structure">
          <section className="full-page error-page">
            <img
              className="error-image"
              src={errorImage}
              alt="Error Recovery"
            />
            <div className="error-text">
              <h2>Oops!</h2>
              <p>
                It looks like we took a wrong turn! Don’t panic though, you
                should be able to find what you’re looking for in the locations
                below:
              </p>
              <ul>
                {isBooker && bookerErrorLinks()}
                {isProvider && providerErrorLinks()}
                {!isBooker && !isProvider && defaultErrorLinks()}
              </ul>
            </div>
          </section>
          <Footer />
        </div>
      </IonContent>
      {isBooker && <BookerBottomNav />}
      {isProvider && <ProviderBottomNav />}
    </IonPage>
  );
};

export default ErrorPage;
