import actionTypes from 'constants/actionTypes';

const initialState = {
  superPowers: {},
  superPowersLoaded: false,
  superPowerUpdated: false,
  moreAboutYou: {},
  moreAboutYouLoaded: false,
  moreAboutYouSaved: false,
  profile: {},
  profileSaved: false,
  profileLoaded: false,
  references: {},
  referencesSaved: false,
  referencesLoaded: false,
  personality: {},
  personalitySaved: false,
  personalityLoaded: false,
  personalityTaken: false,
  competencyTest: {},
  competencyTestLoaded: false,
  hasCalledIDV: false,
  idv: [],
  successfullyAnsweredIDV: false,
  errors: {},
  loading: false,
  alreadyVerified: false,
  insufficientInfo: false,
  gotVerifyUIToken: false,
  verifyUIToken: '',
  avatarSaved: false,
  bioSaved: false,
  moreAboutYouUpdated: false,
  services: {},
  servicesLoaded: false,
  servicesUpdated: false
};

const onboardingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROVIDER_ONBOARDING_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case actionTypes.PROVIDER_ONBOARDING_SUPER_POWERS_RESET:
      return {
        ...state,
        superPowerUpdated: false
      };
    case actionTypes.PROVIDER_ONBOARDING_SUPER_POWERS_LOAD:
      return {
        ...state,
        superPowers: { ...action.superPowers },
        superPowersLoaded: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_SERVICE_TYPES_LOAD:
      return {
        ...state,
        services: { ...action.services },
        servicesLoaded: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_SUPER_POWERS_UPDATE:
      return {
        ...state,
        superPowers: { ...action.superPowers },
        superPowerUpdated: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_SERVICE_TYPES_UPDATE:
      return {
        ...state,
        services: { ...action.services },
        servicesUpdated: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_MORE_ABOUT_YOU_RESET:
      return {
        ...state,
        moreAboutYouSaved: false
      };
    case actionTypes.PROVIDER_ONBOARDING_MORE_ABOUT_YOU_LOAD:
      return {
        ...state,
        moreAboutYou: { ...action.moreAboutYou },
        moreAboutYouLoaded: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_MORE_ABOUT_YOU_UPDATE:
      return {
        ...state,
        moreAboutYou: { ...action.moreAboutYou },
        loading: false,
        moreAboutYouUpdated: true
      };
    case actionTypes.PROVIDER_ONBOARDING_MORE_ABOUT_YOU_SAVE:
      return {
        ...state,
        moreAboutYou: { ...action.moreAboutYou },
        moreAboutYouSaved: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_PROFILE_UPDATE:
      return {
        ...state,
        profile: { ...action.profile },
        loading: false
      };
    case actionTypes.PROVIDER_ONBOARDING_PROFILE_SAVE:
      return {
        ...state,
        profile: { ...action.profile },
        profileSaved: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_PROFILE_RESET:
      return {
        ...state,
        profileSaved: false
      };
    case actionTypes.PROVIDER_ONBOARDING_PROFILE_LOAD:
      return {
        ...state,
        profile: { ...action.profile },
        profileLoaded: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_REFERENCES_UPDATE:
      return {
        ...state,
        references: { ...action.references },
        loading: false
      };
    case actionTypes.PROVIDER_ONBOARDING_REFERENCES_SAVE:
      return {
        ...state,
        references: { ...action.references },
        referencesSaved: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_REFERENCES_RESET:
      return {
        ...state,
        referencesSaved: false
      };
    case actionTypes.PROVIDER_ONBOARDING_REFERENCES_LOAD:
      return {
        ...state,
        references: { ...action.references },
        referencesLoaded: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_PERSONALITY_TAKEN:
      return {
        ...state,
        personalityLoaded: true,
        personalityTaken: action.personalityTaken,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_PERSONALITY_SAVE:
      return {
        ...state,
        personality: { ...action.personality },
        personalityTaken: true,
        personalitySaved: true,
        personalityLoaded: true,
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_PERSONALITY_UPDATE:
      return {
        ...state,
        personality: { ...action.personality },
        loading: false,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_SKILLS_SENT:
      return {
        ...state,
        loading: false,
        competencyTest: { ...action.competencyTest },
        competencyTestLoaded: true
      };
    case actionTypes.PROVIDER_ONBOARDING_IDV_LOADED:
      return {
        ...state,
        hasCalledIDV: true,
        idv: action.idv,
        loading: false,
        errors: action.errors || {}
      };
    case actionTypes.PROVIDER_ONBOARDING_IDV_ANSWERED:
      return {
        ...state,
        hasCalledIDV: true,
        successfullyAnsweredIDV: action.successfullyAnsweredIDV,
        loading: false,
        errors: action.errors || {},
        alreadyVerified: action.alreadyVerified,
        insufficientInfo: action.insufficientInfo
      };
    case actionTypes.PROVIDER_ONBOARDING_RESET_IDV_DATA:
      return {
        ...state,
        hasCalledIDV: false,
        successfullyAnsweredIDV: false,
        idv: [],
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_ERRORS:
      return {
        ...state,
        loading: false,
        errors: { ...action.errors }
      };
    case actionTypes.PROVIDER_ONBOARDING_RESET_ERRORS:
      return {
        ...state,
        errors: {}
      };
    case actionTypes.PROVIDER_ONBOARDING_GET_VERIFYUI_TOKEN:
      return {
        ...state,
        loading: false,
        errors: action.errors || state.errors,
        gotVerifyUIToken: true,
        verifyUIToken: action.token
      };
    default:
      return state;
  }
};

export default onboardingReducer;
