import actionTypes from 'constants/actionTypes';
import { api } from '../../api/connect';

export function getShortlists() {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });

    api()
      .get('/bookers/shortlists')
      .then((response) => {
        const { shortlists } = response.data;
        dispatch({
          shortlists,
          type: actionTypes.SHORTLIST_LOADED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistsErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistsErrors = { message: data.error };
          } else {
            shortlistsErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistsErrors
        });
      });
  };
}

export function getShortlist(shortlistId) {
  return (dispatch, getState) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });

    api()
      .get(`/bookers/shortlists/${shortlistId}`)
      .then((response) => {
        const { user } = getState().userReducer;
        const { shortlist } = response.data;
        const isMyShortlist = shortlist.owners.find(
          (owner) => owner.user_uuid === user.uuid
        );
        dispatch({
          shortlist,
          type: actionTypes.SHORTLIST_ALL_UPDATE
        });
        if (isMyShortlist) {
          dispatch({
            shortlist,
            type: actionTypes.SHORTLIST_UPDATE
          });
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistErrors = { message: data.error };
          } else {
            shortlistErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
      });
  };
}

export function createShortlist(shortlistName, callback) {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });

    api()
      .post('/bookers/shortlists', { name: shortlistName })
      .then((response) => {
        const { data = {} } = response;
        const { shortlist } = data;
        dispatch({
          shortlist,
          type: actionTypes.SHORTLIST_CREATE
        });
        if (typeof callback === 'function') {
          callback(shortlist);
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistErrors = { message: data.error };
          } else {
            shortlistErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
      });
  };
}

export function addToShortlists({ shortlistIds, providerId }) {
  return (dispatch, getState) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });

    const allRequests = [];
    shortlistIds.forEach((shortlistId) => {
      const request = api().post(
        `/bookers/shortlists/${shortlistId}/add_members`,
        { shortlist: [{ member_id: providerId, member_type: 'Provider' }] }
      );
      allRequests.push(request);
    });

    Promise.all(allRequests)
      .then((responses) => {
        responses.forEach((response) => {
          const { data = {} } = response;
          const { shortlist } = data;
          dispatch({
            shortlist,
            type: actionTypes.SHORTLIST_UPDATE
          });
          dispatch({
            shortlist,
            type: actionTypes.SHORTLIST_ALL_UPDATE
          });
          dispatch({
            type: actionTypes.SHORTLIST_PROVIDER_ADDED
          });
        });
      })
      .catch((e) => {
        const { response = {}, config = {} } = e;
        const { url = '' } = config;
        const splittedAddress = url.split('/');
        const shortlistId =
          splittedAddress.length > 1
            ? splittedAddress[splittedAddress.length - 2]
            : 0;
        const { shortlists = [] } = getState().shortlistReducer;
        const shortlist = shortlists.find((s) => s.uuid === shortlistId);
        let shortlistErrors = { message: 'Something went wrong' };
        if (shortlist && shortlist.uuid) {
          shortlistErrors = {
            message: `It was not possible to add to ${shortlist.name}`
          };
        } else {
          const { data = {} } = response;
          if (data.error) {
            if (typeof data.error === 'string') {
              shortlistErrors = { message: data.error };
            } else {
              shortlistErrors = { ...data.error };
            }
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
        dispatch(getShortlists());
      });
  };
}

export function addMembers({ shortlistId, memberIds, isClone, callback }) {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });

    const newShortlist = memberIds.map((id) => ({
      member_id: id,
      member_type: 'Provider'
    }));
    api()
      .post(`/bookers/shortlists/${shortlistId}/add_members`, {
        shortlist: newShortlist
      })
      .then((response) => {
        const { data = {} } = response;
        const { shortlist } = data;
        dispatch({
          shortlist,
          type: actionTypes.SHORTLIST_UPDATE
        });
        dispatch({
          shortlist,
          type: actionTypes.SHORTLIST_ALL_UPDATE
        });
        dispatch({
          type: actionTypes.SHORTLIST_PROVIDER_ADDED
        });
        if (isClone) {
          dispatch({
            type: actionTypes.SHORTLIST_CLONE
          });
          if (typeof callback === 'function') {
            callback(shortlist);
          }
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistErrors = { message: data.error };
          } else {
            shortlistErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
      });
  };
}

export function removeFromShortlist({ shortlistId, providerId }) {
  return (dispatch) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });

    api()
      .post(`/bookers/shortlists/${shortlistId}/remove_member`, {
        shortlist: { member_id: providerId, member_type: 'Provider' }
      })
      .then((response) => {
        const { data = {} } = response;
        const { shortlist } = data;
        dispatch({
          shortlist,
          type: actionTypes.SHORTLIST_UPDATE
        });
        dispatch({
          shortlist,
          type: actionTypes.SHORTLIST_ALL_UPDATE
        });
        dispatch({
          type: actionTypes.SHORTLIST_PROVIDER_REMOVED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistErrors = { message: data.error };
          } else {
            shortlistErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
      });
  };
}

export function resetShortlistIndicators() {
  return (dispatch) => {
    dispatch({
      successfullyAdded: false,
      successfullyDeleted: false,
      type: actionTypes.SHORTLIST_MODAL_OPEN
    });
  };
}

export function deleteShortlist(shortlistId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SHORTLIST_LOADING,
      loading: true
    });

    api()
      .delete(`/bookers/shortlists/${shortlistId}`)
      .then(() => {
        dispatch({
          shortlistId,
          type: actionTypes.SHORTLIST_DELETE
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistErrors = {
          message: 'It was not possible to remove this shortlist'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistErrors = { message: data.error };
          } else {
            shortlistErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
      });
  };
}

export function cloneShortlist(shortlistId, callback) {
  return async (dispatch, getState) => {
    dispatch({
      loading: true,
      type: actionTypes.SHORTLIST_LOADING
    });
    const { shortlists = [] } = getState().shortlistReducer;
    let shortlistToClone = shortlists.find((s) => s.uuid === shortlistId);
    if (!shortlistToClone || !shortlistToClone.uuid) {
      try {
        const { data = {} } = await api().get(
          `/bookers/shortlists/${shortlistId}`
        );
        shortlistToClone = { ...data.shortlist };
      } catch {
        return dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: { message: 'Shortlist not found' }
        });
      }
    }

    return api()
      .post('/bookers/shortlists', { name: shortlistToClone.name })
      .then((response) => {
        const { data = {} } = response;
        const { shortlist } = data;
        dispatch({
          shortlist,
          loading: true,
          type: actionTypes.SHORTLIST_CREATE
        });
        if (typeof callback === 'function') {
          const members = shortlistToClone.members.map(
            (member) => member.provider_id
          );
          if (members.length > 0) {
            dispatch(
              addMembers({
                shortlistId: shortlist.uuid,
                memberIds: members,
                isClone: true,
                callback
              })
            );
          } else {
            dispatch({
              type: actionTypes.SHORTLIST_CLONE
            });
            if (typeof callback === 'function') {
              callback(shortlist);
            }
          }
        }
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let shortlistErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            shortlistErrors = { message: data.error };
          } else {
            shortlistErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.SHORTLIST_ERRORS,
          errors: shortlistErrors
        });
      });
  };
}
