/* eslint-disable import/prefer-default-export */
import actionTypes from 'constants/actionTypes';
import { api } from 'api/connect';

export function getUrgentNotifications() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });

    api()
      .get('/user_urgent_actions')
      .then((response) => {
        const { data = [] } = response || {};
        const notifications = data;
        dispatch({
          type: actionTypes.NOTIFICATIONS_LOAD,
          notifications
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function closeUrgentNotification(notificationId, waitAfterClose = 0.3) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    dispatch({
      type: actionTypes.NOTIFICATIONS_REMOVE,
      notificationId
    });

    // wait until show next notification
    window.setTimeout(() => {
      dispatch({
        type: actionTypes.NOTIFICATIONS_SHOW,
        show: true
      });
    }, waitAfterClose * 1000);
  };
}

export function addSingleUrgentNotification(notification) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    dispatch({
      type: actionTypes.NOTIFICATIONS_ADD,
      notification
    });
  };
}

export function bookingFirstCheckin({ communicationsRecordId, checkinAction }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .post(
        `/booking_communications_records/${communicationsRecordId}/first_checkin?response=${checkinAction}`
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_CHECKED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function bookerNotifiedProviderEngaged({ communicationsRecordId }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .post(
        `/booking_communications_records/${communicationsRecordId}/booker_viewed_booking_post_engage`
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_CHECKED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function bookerContactConfirmation({
  bookingCommunicationsRecordId,
  confirmationAnswer
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .post(
        `/booking_communications_records/${bookingCommunicationsRecordId}/booker_contacted?contact_method=${confirmationAnswer}`
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_BOOKER_CONTACTED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function bookingSecondCheckin({
  communicationsRecordId,
  checkinAction
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .post(
        `/booking_communications_records/${communicationsRecordId}/second_checkin?response=${checkinAction}`
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_CHECKED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}
export function finalizeBookingForBooker({
  bookingId,
  bookingType,
  providerId
}) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .post(
        `/bookers/bookings/${bookingId}/finalize?booking_type=${bookingType}&provider_id=${providerId}`
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_CHECKED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function finalizeBookingByProvider({ bookingId, bookingType }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });

    api()
      .post(
        `/provider/bookings/${bookingId}/finalize?booking_type=${bookingType}`
      )
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_CHECKED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = {
          message: 'Something went wrong on urgent notifications'
        };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function bookerDisputeReconciliation(bookingId, jobType) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .post(`/bookers/bookings/${bookingId}/dispute`, {
        booking_type: jobType
      })
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_CHECKED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}

export function removeUrgentActionOnBE(notificationId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.NOTIFICATIONS_LOADING,
      loading: true
    });
    api()
      .delete(`/user_urgent_actions/${notificationId}`)
      .then(() => {
        dispatch({
          type: actionTypes.NOTIFICATION_DELETED
        });
      })
      .catch((e) => {
        const { response = {} } = e;
        const { data = {} } = response;
        let notificationErrors = { message: 'Something went wrong' };
        if (data.error) {
          if (typeof data.error === 'string') {
            notificationErrors = { message: data.error };
          } else {
            notificationErrors = { ...data.error };
          }
        }
        dispatch({
          type: actionTypes.NOTIFICATIONS_ERRORS,
          errors: notificationErrors
        });
      });
  };
}
