import React from 'react';
import ErrorPage from 'common/ErrorPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /* eslint-disable-next-line */
  componentDidCatch(error, errorInfo) {
    // Add honeybadger event
    if (process.env.NODE_ENV === 'production') {
      const { honeybadger, isLoggedIn, user = {} } = this.props;
      if (isLoggedIn && honeybadger) {
        honeybadger.setContext({
          user_id: user.id,
          user_email: user.email
        });
      }
      honeybadger.notify(error);
    }
    console.log({ error, errorInfo });
  }

  resetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <ErrorPage
          resetError={this.resetError}
          currentPage={window.location.pathname}
        />
      );
    }
    return children;
  }
}

export default ErrorBoundary;
